import { useState, useEffect, useRef } from "react";
import { GoPaperclip } from "react-icons/go";
import SelectRange from "./ranges"
import Header from "./header";
import logoImg from "../assets/icons/logo.png"
import { BiLike, BiDislike, BiCopyAlt } from "react-icons/bi";
import { PiPaperPlaneRightBold, PiFolderBold, PiLightningBold } from "react-icons/pi";
import { Link } from "react-router-dom"
import { FaPlus, FaRobot, FaSearch, FaUsers, FaFlask, FaBolt, FaBook, FaFile, FaAndroid, FaApple, FaSpinner } from 'react-icons/fa';
import axios from "axios";
import { db, auth, onAuthStateChanged } from '../firebase';
import { collection, getFirestore, getDocs, serverTimestamp, doc, setDoc, getDoc } from 'firebase/firestore';
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon, SparklesIcon, XIcon, UserCircleIcon, MenuIcon } from "@heroicons/react/solid";
import { PaperAirplaneIcon, } from "@heroicons/react/solid";
import { BookOpenIcon, BriefcaseIcon, CogIcon, FileIcon, HomeIcon } from "lucide-react";
import jsonData from "../assets/data.json"

const Chat = ({ user, signIn, userName, userAge, selectedLanguage }) => {

    const [sources, setSources] = useState()
    const [question, setQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSourceIndex, setSelectedSourceIndex] = useState({ messageIndex: null, sourceIndex: null });

    const userCollectionRef = collection(db, "saved_chats");

    useEffect(() => {
        if (user) {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user) {
                    loadChatHistory(user.uid);
                }
            });
            return () => unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (user && auth.currentUser) {
            saveChatHistory(auth.currentUser.uid);
        }
    }, [chatHistory]);

    const loadChatHistory = async (userId) => {
        const docRef = doc(userCollectionRef, userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setChatHistory(docSnap.data().chatHistory);
        } else {
            console.log("No such document!");
        }
    };

    const saveChatHistory = async (userId) => {
        try {
            await setDoc(doc(userCollectionRef, userId), {
                chatHistory: chatHistory,
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setChatHistory((prevChatHistory) => [
            ...prevChatHistory,
            { user: question, athena: '', sources: [], roberta_large_analysis: { entailment: 0, neutral: 0, contradiction: 0 } },
        ]);

        setQuestion('');
        setIsLoading(true);

        try {
            const eventSource = new EventSource(`https://konlaptechs.com/api/call-api?question=${encodeURIComponent(question)}&selectedLanguage=${encodeURIComponent(selectedLanguage)}`);

            let lastNonFinalMessageData = null;

            eventSource.onmessage = (event) => {
                setIsLoading(false);
                const data = JSON.parse(event.data);

                if (data.citations) {
                    const entailmentCount = data?.citations?.filter(source => source?.abstractRelation === "entailment")?.length;
                    const neutralCount = data?.citations.filter(source => source?.abstractRelation === "neutral")?.length;
                    const contradictionCount = data?.citations?.filter(source => source?.abstractRelation === "contradiction")?.length;

                    lastNonFinalMessageData = {
                        sources: data?.citations,
                        roberta_large_analysis: {
                            entailment: entailmentCount,
                            neutral: neutralCount,
                            contradiction: contradictionCount
                        }
                    };
                }

                if (data.streamingResponse) {
                    let rawData = data.streamingResponse;
                    rawData = rawData.replace(/^data:\s*/, '').trim();

                    if (rawData === '[DONE]') {
                        eventSource.close();
                        return;
                    }

                    const streamingData = JSON.parse(rawData);

                    if (
                        streamingData.choices &&
                        streamingData.choices[0].delta &&
                        streamingData.choices[0].delta.context &&
                        streamingData.choices[0].delta.context.messages &&
                        streamingData.choices[0].delta.context.messages[0].content
                    ) {
                        const content = JSON.parse(streamingData.choices[0].delta.context.messages[0].content);
                        const citations = content.citations;

                        setChatHistory((prevChatHistory) => {
                            const lastMessageIndex = prevChatHistory.length - 1;
                            const lastMessage = prevChatHistory[lastMessageIndex];
                            if (lastMessage && lastMessage.user === question) {
                                const updatedMessage = {
                                    ...lastMessage,
                                    sources: citations,
                                    roberta_large_analysis: {
                                        entailment: lastNonFinalMessageData?.roberta_large_analysis?.entailment || 0,
                                        neutral: lastNonFinalMessageData?.roberta_large_analysis?.neutral || 0,
                                        contradiction: lastNonFinalMessageData?.roberta_large_analysis?.contradiction || 0
                                    }
                                };
                                return prevChatHistory.map((message, index) =>
                                    index === lastMessageIndex ? updatedMessage : message
                                );
                            }
                            return prevChatHistory;
                        });
                    }

                    if (streamingData.choices && streamingData.choices[0].delta && streamingData.choices[0].delta.content) {
                        setChatHistory((prevChatHistory) => {
                            const lastMessageIndex = prevChatHistory.length - 1;
                            const lastMessage = prevChatHistory[lastMessageIndex];
                            if (lastMessage && lastMessage.user === question) {
                                return prevChatHistory.map((message, index) =>
                                    index === lastMessageIndex
                                        ? {
                                            ...message,
                                            athena: (message.athena || '') + streamingData.choices[0].delta.content,
                                            roberta_large_analysis: lastNonFinalMessageData?.roberta_large_analysis || message.roberta_large_analysis,
                                            sources: lastNonFinalMessageData?.sources || message.sources
                                        }
                                        : message
                                );
                            } else {
                                return [
                                    ...prevChatHistory,
                                    {
                                        user: question,
                                        athena: streamingData.choices[0].delta.content,
                                        sources: lastNonFinalMessageData?.sources || [],
                                        roberta_large_analysis: lastNonFinalMessageData?.roberta_large_analysis || { entailment: 0, neutral: 0, contradiction: 0 }
                                    },
                                ];
                            }
                        });
                    }
                }
            };

            eventSource.onerror = (error) => {
                console.error('Error:', error);
                eventSource.close();
                const errorMessage = {
                    user: question,
                    athena: 'An error occurred',
                };
                setChatHistory((prevChatHistory) => [...prevChatHistory, errorMessage]);
                setQuestion('');
            };

            eventSource.onopen = () => { };

            eventSource.onclose = () => { };

        } catch (error) {
            console.error('Error:', error);
            const errorMessage = {
                user: question,
                athena: 'An error occurred',
            };
            setChatHistory((prevChatHistory) => [...prevChatHistory, errorMessage]);
            setQuestion('');
        }
    };

    const [mainChat, setMainChat] = useState(true)

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => setIsOpen(!isOpen);


    const [start, setStart] = useState()
    const [end, setEnd] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleLogout = () => {
        auth.signOut();
    };

    const [links, setLinks] = useState("")

    const getLinks = async (requestLink) => {
        try {
            const response = await axios.get('https://konlaptechs.com/api/google-scholar-search', {
                params: { query: requestLink },
            });
            setLinks(response.data.url);
            console.log("first: ", response.data.url)
            window.open(response.data.url, '_blank');
        } catch (error) {
            if (error.response) {
            } else {
            }
        }
    };

    const [research, setResearch] = useState(true)
    const [playground, setPlayground] = useState(false)

    return (
        <>
            <div className="flex sm:hidden">
                <Header />
            </div>
            {showPopup && (
                <SelectRange
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                    onClose={closePopup}
                    selectedLanguage={selectedLanguage}
                />
            )}
            <PopupComponent isOpen={isOpen} togglePopup={togglePopup} />
            <div className="flex flex-row h-screen">
                <aside className="w-0 sm:w-16 bg-gray-100 overflow-y-auto p-4 hidden sm:flex flex-col fixed h-full">
                    <nav className="flex-grow">
                        <ul className="flex flex-col items-center space-y-6 py-6">
                            <li>
                                <Link to="/" className="text-gray-500 hover:text-indigo-600 cursor-pointer">
                                    <HomeIcon className="w-6 h-6" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="flex flex-col items-center space-y-6 pb-6">
                        <div className="w-full flex items-center justify-center">
                            {user ? (
                                <div className="flex items-center">
                                    {user.photoURL ? (
                                        <img
                                            onClick={handleLogout}
                                            className="w-6 h-6 rounded-full cursor-pointer object-cover"
                                            src={user.photoURL}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = '../assets/icons/users.svg';
                                            }}
                                            alt="User profile"
                                        />
                                    ) : (
                                        <UserCircleIcon onClick={handleLogout} className="w-6 h-6 text-gray-500 cursor-pointer hover:text-indigo-600" />
                                    )}
                                </div>
                            ) : (
                                <UserCircleIcon onClick={signIn} className="w-6 h-6 text-gray-500 cursor-pointer hover:text-indigo-600" />
                            )}
                        </div>
                    </div>
                </aside>
                <div className="w-full sm:pl-16">
                    <div className="flex justify-center bg-gray-50 h-full">
                        <div className="w-full bg-white px-3 py-6 sm:px-0 h-full flex flex-col">
                            {mainChat && (
                                <div className="flex flex-col h-full">
                                    {research && (
                                        <div className="flex-grow sm:px-32 flex flex-col">
                                            <div className="flex-grow overflow-y-auto" style={{ maxHeight: 'calc(100% - 80px)' }}>
                                                <div className="flex flex-col p-2 sm:p-12 gap-4">
                                                    {chatHistory.map((message, index) => (
                                                        <div className="" key={index}>
                                                            <div key={index} className="">
                                                                {message.user && (
                                                                    <div className="">
                                                                        <div className="flex">
                                                                            <div className="w-12">
                                                                                {user.photoURL ? (
                                                                                    <div className="flex items-center">
                                                                                        <img onClick={handleLogout}
                                                                                            className='h-auto w-8 rounded-full cursor-pointer' src={user.photoURL}
                                                                                            onError={(e) => { e.target.onerror = null; e.target.src = '../assets/icons/users.svg'; }}
                                                                                            alt="User profile" />
                                                                                    </div>
                                                                                ) : (
                                                                                    <UserCircleIcon onClick={handleLogout} className='h-auto w-9 text-yellow-600 cursor-pointer hover:text-blue-500' />
                                                                                )}
                                                                            </div>
                                                                            <div className="w-4/5 sm:w-1/2">
                                                                                <div className="w-full rounded-lg flex mb-4">
                                                                                    <p className="">{message.user}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {message.athena && (
                                                                    <div className="sm:flex py-3">
                                                                        <div className="w-12">
                                                                            {user.photoURL ? (
                                                                                <div className="flex items-center">
                                                                                    <img onClick={handleLogout}
                                                                                        className='h-auto w-8 rounded-full cursor-pointer' src={logoImg}
                                                                                        onError={(e) => { e.target.onerror = null; e.target.src = '../assets/icons/users.svg'; }}
                                                                                        alt="User profile" />
                                                                                </div>
                                                                            ) : (
                                                                                <UserCircleIcon onClick={handleLogout} className='h-auto w-9 text-yellow-600 cursor-pointer hover:text-blue-500' />
                                                                            )}
                                                                        </div>
                                                                        <div className={`font-sans ${message?.roberta_large_analysis?.entailment > 0 ||
                                                                            message?.roberta_large_analysis?.neutral > 0 ||
                                                                            message?.roberta_large_analysis?.contradiction > 0
                                                                            ? "w-full sm:w-1/2"
                                                                            : "w-full"
                                                                            } rounded-lg`}>
                                                                            <p className="text-justify">
                                                                                {typeof message.athena === 'string' ? (
                                                                                    message.athena.split(/(\[doc\d+\])/).map((part, partIndex) => {
                                                                                        const match = part.match(/^\[doc(\d+)\]$/);
                                                                                        if (match) {
                                                                                            const number = match[1];
                                                                                            return (
                                                                                                <span
                                                                                                    key={partIndex}
                                                                                                    className="inline-flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-blue-100 text-blue-800 font-medium"
                                                                                                    onClick={() => setSelectedSourceIndex({ messageIndex: index, sourceIndex: parseInt(number) - 1 })}
                                                                                                >
                                                                                                    {number}
                                                                                                </span>
                                                                                            );
                                                                                        }
                                                                                        return part;
                                                                                    })
                                                                                ) : (
                                                                                    message.athena
                                                                                )}
                                                                            </p>
                                                                            {selectedSourceIndex.messageIndex === index && (
                                                                                <div className="bg-gray-100 text-gray-600 font-sans rounded-lg py-3 px-1 font-semibold mt-2 relative">
                                                                                    <a onClick={() => getLinks(message.sources[selectedSourceIndex.sourceIndex]?.content)} target="_blank" className="text-sm cursor-pointer hover:underline">
                                                                                        {message.sources[selectedSourceIndex.sourceIndex]?.content || "No source available"}
                                                                                    </a>
                                                                                    <button
                                                                                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                                                                        onClick={() => setSelectedSourceIndex({ messageIndex: null, sourceIndex: null })}
                                                                                    >
                                                                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                            <div className="flex pt-2 pb-6 space-x-2 items-center">
                                                                                <BiCopyAlt className="w-5 h-auto text-gray-400 cursor-pointer hover:text-gray-800" />
                                                                                <BiLike className="w-5 h-auto text-gray-400 cursor-pointer hover:text-gray-800" />
                                                                                <BiDislike className="w-5 h-auto text-gray-400 cursor-pointer hover:text-gray-800" />
                                                                            </div>
                                                                        </div>
                                                                        {(message?.roberta_large_analysis?.entailment > 0 || message?.roberta_large_analysis?.neutral > 0 || message?.roberta_large_analysis?.contradiction > 0) && (
                                                                            <div className='w-full mx-auto sm:w-2/5'>
                                                                                <p className='text-gray-800 text-sm sm:text-base font-semibold pb-2'>
                                                                                    {selectedLanguage === "es-ES" ? "Análisis de papers" : "Papers analysis"}
                                                                                </p>
                                                                                <>
                                                                                    <a className='my-2 flex items-center'
                                                                                    >
                                                                                        <CheckCircleIcon className='text-green-500 w-5 h-5 mr-2' />
                                                                                        <p className='text-gray-500 text-sm sm:text-base'>
                                                                                            {selectedLanguage === "es-ES" ? "Sí: " : "Yes: "}
                                                                                            {Math.round((message?.roberta_large_analysis?.entailment / (message?.roberta_large_analysis?.entailment + message?.roberta_large_analysis?.neutral + message?.roberta_large_analysis?.contradiction)) * 100)}%
                                                                                        </p>
                                                                                    </a>
                                                                                    <div className='my-2'>
                                                                                        <div className="bg-gray-200 h-3 w-full rounded">
                                                                                            <div
                                                                                                onClick={() => setSelectedCategory('entailment')}
                                                                                                className="bg-green-500 cursor-pointer mb-6 h-3 rounded"
                                                                                                style={{ width: `${(message?.roberta_large_analysis?.entailment / (message?.roberta_large_analysis?.entailment + message?.roberta_large_analysis?.neutral + message?.roberta_large_analysis?.contradiction)) * 100}%` }}
                                                                                            ></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                <>
                                                                                    <a className='my-2 flex items-center'
                                                                                    >
                                                                                        <QuestionMarkCircleIcon className='text-yellow-500 w-5 h-5 mr-2' />
                                                                                        <p className='text-gray-500 text-sm sm:text-base'>
                                                                                            {selectedLanguage === "es-ES" ? "Quizás: " : "Maybe: "}
                                                                                            {Math.round((message.roberta_large_analysis.neutral / (message.roberta_large_analysis.entailment + message.roberta_large_analysis.neutral + message.roberta_large_analysis.contradiction)) * 100)}%
                                                                                        </p>
                                                                                    </a>
                                                                                    <div className='my-2'>
                                                                                        <div className="bg-gray-200 h-3 w-full rounded">
                                                                                            <div
                                                                                                onClick={() => setSelectedCategory('neutral')}
                                                                                                className="bg-yellow-500 cursor-pointer mb-6 h-3 rounded"
                                                                                                style={{ width: `${(message?.roberta_large_analysis?.neutral / (message?.roberta_large_analysis?.entailment + message?.roberta_large_analysis?.neutral + message?.roberta_large_analysis?.contradiction)) * 100}%` }}
                                                                                            ></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>

                                                                                <>
                                                                                    <a className='my-2 flex items-center'
                                                                                    >
                                                                                        <XCircleIcon className='text-red-500 w-5 h-5 mr-2' />
                                                                                        <p className='text-gray-500 text-sm sm:text-base'>
                                                                                            {selectedLanguage === "es-ES" ? "No: " : "No: "}
                                                                                            {Math.round((message.roberta_large_analysis.contradiction / (message.roberta_large_analysis.entailment + message.roberta_large_analysis.neutral + message.roberta_large_analysis.contradiction)) * 100)}%
                                                                                        </p>
                                                                                    </a>
                                                                                    <div className='my-2'>
                                                                                        <div className="bg-gray-200 h-3 w-full rounded">
                                                                                            <div
                                                                                                onClick={() => setSelectedCategory('contradiction')}
                                                                                                className="bg-red-500 cursor-pointer mb-6 h-3 rounded"
                                                                                                style={{ width: `${(message?.roberta_large_analysis?.contradiction / (message?.roberta_large_analysis?.entailment + message?.roberta_large_analysis?.neutral + message?.roberta_large_analysis?.contradiction)) * 100}%` }}
                                                                                            ></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>

                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                {selectedCategory &&
                                                                    message.sources
                                                                        .filter(source => source.abstractRelation === selectedCategory)
                                                                        .map((source, index) => (
                                                                            <div className="w-full sm:w-1/2">
                                                                                <div key={index} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                                    <ul style={{ textAlign: 'left' }}>
                                                                                        <li>
                                                                                            <button className="hover:underline my-1 flex text-justify text-sm cursor-pointer text-gray-700 font-medium" onClick={() => setSelectedSourceIndex(index)}>
                                                                                                <a className="line-clamp-1">{index + 1}. {source.content || 'No source available'}</a>
                                                                                            </button>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                }
                                                                {isLoading && index === chatHistory.length - 1 && (
                                                                    <div className="">
                                                                        <div className='ml-12'>
                                                                            <div className='flex w-full justify-start'>
                                                                                <div class="my-6 w-11/12 bg-white sm:w-1/2">
                                                                                    <div class="animate-pulse flex space-x-4">
                                                                                        <div class="flex-1 space-y-12 py-2">
                                                                                            <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                                <div class="grid grid-cols-3 gap-4">
                                                                                                    <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                                    <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                                                                    <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                                                                </div>
                                                                                                <div class="h-3 bg-indigo-200 rounded"></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="bg-white sm:px-60 py-4 sticky bottom-0">
                                                <form onSubmit={user ? handleSubmit : signIn} className="flex p-2 rounded-lg border border-indigo-600">
                                                    <input
                                                        type="text"
                                                        placeholder={
                                                            selectedLanguage === 'es-ES'
                                                                ? 'Empieza a escribir acá...'
                                                                : 'Start typing here...'
                                                        }
                                                        value={question}
                                                        onChange={(e) => setQuestion(e.target.value)}
                                                        className="flex-grow bg-transparent rounded-3xl outline-none text-gray-700"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={togglePopup}
                                                        className="mx-5"
                                                    >
                                                        <PiLightningBold className="w-5 text-indigo-600 hover:text-indigo-800 h-auto" />
                                                    </button>
                                                    <button type="submit" className="rounded p-3 bg-indigo-600 hover:bg-indigo-500">
                                                        <PiPaperPlaneRightBold className="w-5 text-white h-auto" />
                                                    </button>
                                                </form>
                                            </div>
                                            {sources && (
                                                <div className="hidden lg:block w-1/4 px-4 py-6 bg-gray-100 overflow-y-auto">
                                                    <p className="text-gray-800 font-medium">Citations:</p>
                                                    <div className="text-justify text-sm text-gray-700">
                                                        {JSON.parse(sources).citations.slice(0, 5).map((citation, index) => (
                                                            <span key={index}>
                                                                {citation.content.split('\n').slice(0, 2).join('\n')}
                                                                {index < 4 && <br />}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {playground && <AzureOpenAIGraph user={user} selectedLanguage={selectedLanguage} />}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chat;



const AzureOpenAIGraph = ({ selectedLanguage }) => {
    const [inputText, setInputText] = useState('');
    const [file, setFile] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        const getCsrfToken = async () => {
            try {
                const response = await fetch('https://konlaptechs.com/api/csrf-token');
                const data = await response.json();
                setCsrfToken(data.csrfToken);
            } catch (err) {
                console.error('Failed to fetch CSRF token:', err);
                setError('Failed to initialize. Please try refreshing the page.');
            }
        };
        getCsrfToken();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputText && !file) {
            setError('Please either enter a message or upload a file.');
            return;
        }
        if (!csrfToken) {
            setError('CSRF token not available. Please try refreshing the page.');
            return;
        }
        setLoading(true);
        setError(null);

        const userMessage = {
            role: 'user',
            content: [{ type: 'text', value: inputText }]
        };
        setMessages(prevMessages => [...prevMessages, userMessage]);

        const formData = new FormData();
        if (inputText) formData.append('sales_data', inputText);
        if (file) formData.append('file', file);
        formData.append('language', selectedLanguage);

        const endpoint = file ? 'https://konlaptechs.com/api/apolo-file' : 'https://konlaptechs.com/api/apolo-text';

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRFToken': csrfToken,
                },
                credentials: 'include',
            });
            const data = await response.json();
            console.log(data);
            const apiResponse = data.result.filter((msg, index) => index !== 1);
            setMessages(prevMessages => [...prevMessages, ...apiResponse]);
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred');
        }
        setLoading(false);
        setInputText('');
        setFile(null);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <div className="flex flex-col w-full h-screen px-16">
            <div className="flex-grow overflow-y-auto p-4" style={{ maxHeight: 'calc(100% - 80px)' }}>
                <div className="flex flex-col pb-44 gap-4">
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    {messages.map((msg, index) => (
                        <div key={index} className={`flex ${msg.role === 'user' ? 'justify-end' : 'flex flex-cols justify-start'}`}>
                            <div className={`rounded-lg ${msg.role === 'user' ? 'flex w-1/2 justify-end text-justify p-2 rounded' : ' text-justify w-3/4'} p-3`}>
                                {msg.content.map((content, contentIndex) => (
                                    <div key={contentIndex}>
                                        {content.type === 'text' && <p>{content.value}</p>}
                                        {content.type === 'image' && (
                                            <img src={content.value} alt="Generated graph" className="mt-2 w-3/5 h-auto" />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    {loading &&
                        <div className=''>
                            <div className='flex w-full justify-start'>
                                <div class="my-6 p-4 w-11/12 bg-white sm:w-1/2">
                                    <div class="animate-pulse flex space-x-4">
                                        <div class="flex-1 space-y-12 py-2">
                                            <div class="space-y-3 py-8 border border-gray-100 rounded-lg">
                                                <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                <div class="grid grid-cols-3 gap-4">
                                                    <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                    <div class="h-3 bg-indigo-200 rounded col-span-2"></div>
                                                    <div class="h-3 bg-indigo-200 rounded col-span-1"></div>
                                                </div>
                                                <div class="h-3 bg-indigo-200 rounded"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="bg-white p-4 border mx-16 rounded border-blue-600 sticky bottom-0">
                <form onSubmit={handleSubmit} className="flex items-center space-x-2">
                    <label htmlFor="chat-file-upload" className="cursor-pointer text-gray-500 hover:text-gray-700">
                        <GoPaperclip className="w-6 text-blue-600 h-6" />
                        <input
                            id="chat-file-upload"
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                        />
                    </label>
                    <input
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        className="flex-grow rounded-full px-4 py-2 outline-none"
                        placeholder="Add a file or start a conversation now and add files later..."
                    />
                    <button
                        type="submit"
                        className="bg-blue-600 rounded-full p-2"
                        disabled={loading}
                    >
                        <PiPaperPlaneRightBold className="w-5 text-white h-auto" />
                    </button>
                </form>
            </div>
        </div>
    );
};

const whoData = jsonData.value.map(item => item.IndicatorName);

const dictionaries = {
    "World Bank": [
        "Access to clean fuels and technologies for cooking (% of population)",
        "Access to clean fuels and technologies for cooking, rural (% of rural population)",
        "Access to clean fuels and technologies for cooking, urban (% of urban population)",
        "Access to electricity (% of population)",
        "Access to electricity, rural (% of rural population)",
        "Access to electricity, urban (% of urban population)",
        "Account ownership at a financial institution or with a mobile-money-service provider (% of population ages 15+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, female (% of population ages 15+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, male (% of population ages 15+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, older adults (% of population ages 25+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, poorest 40% (% of population ages 15+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, primary education or less (% of population ages 15+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, richest 60% (% of population ages 15+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, secondary education or more (% of population ages 15+)",
        "Account ownership at a financial institution or with a mobile-money-service provider, young adults (% of population ages 15-24)",
        "Adequacy of social insurance programs (% of total welfare of beneficiary households)",
        "Adequacy of social protection and labor programs (% of total welfare of beneficiary households)",
        "Adequacy of social safety net programs (% of total welfare of beneficiary households)",
        "Adequacy of unemployment benefits and ALMP (% of total welfare of beneficiary households)",
        "Adjusted net enrollment rate, primary (% of primary school age children)",
        "Adjusted net enrollment rate, primary, female (% of primary school age children)",
        "Adjusted net enrollment rate, primary, male (% of primary school age children)",
        "Adjusted net national income (annual % growth)",
        "Adjusted net national income (constant 2015 US$)",
        "Adjusted net national income (current US$)",
        "Adjusted net national income per capita (annual % growth)",
        "Adjusted net national income per capita (constant 2015 US$)",
        "Adjusted net national income per capita (current US$)",
        "Adjusted net savings, excluding particulate emission damage (% of GNI)",
        "Adjusted net savings, excluding particulate emission damage (current US$)",
        "Adjusted net savings, including particulate emission damage (% of GNI)",
        "Adjusted net savings, including particulate emission damage (current US$)",
        "Adjusted savings: carbon dioxide damage (% of GNI)",
        "Adjusted savings: carbon dioxide damage (current US$)",
        "Adjusted savings: consumption of fixed capital (% of GNI)",
        "Adjusted savings: consumption of fixed capital (current US$)",
        "Adjusted savings: education expenditure (% of GNI)",
        "Adjusted savings: education expenditure (current US$)",
        "Adjusted savings: energy depletion (% of GNI)",
        "Adjusted savings: energy depletion (current US$)",
        "Adjusted savings: gross savings (% of GNI)",
        "Adjusted savings: mineral depletion (% of GNI)",
        "Adjusted savings: mineral depletion (current US$)",
        "Adjusted savings: natural resources depletion (% of GNI)",
        "Adjusted savings: net forest depletion (% of GNI)",
        "Adjusted savings: net forest depletion (current US$)",
        "Adjusted savings: net national savings (% of GNI)",
        "Adjusted savings: net national savings (current US$)",
        "Adjusted savings: particulate emission damage (% of GNI)",
        "Adjusted savings: particulate emission damage (current US$)",
        "Adolescent fertility rate (births per 1,000 women ages 15-19)",
        "Adolescents out of school (% of lower secondary school age)",
        "Adolescents out of school, female (% of female lower secondary school age)",
        "Adolescents out of school, male (% of male lower secondary school age)",
        "Adults (ages 15+) and children (ages 0-14) newly infected with HIV",
        "Adults (ages 15-49) newly infected with HIV",
        "Age dependency ratio (% of working-age population)",
        "Age dependency ratio, old (% of working-age population)",
        "Age dependency ratio, young (% of working-age population)",
        "Agricultural irrigated land (% of total agricultural land)",
        "Agricultural land (% of land area)",
        "Agricultural land (sq. km)",
        "Agricultural machinery, tractors",
        "Agricultural machinery, tractors per 100 sq. km of arable land",
        "Agricultural methane emissions (% of total)",
        "Agricultural methane emissions (thousand metric tons of CO2 equivalent)",
        "Agricultural nitrous oxide emissions (% of total)",
        "Agricultural nitrous oxide emissions (thousand metric tons of CO2 equivalent)",
        "Agricultural raw materials exports (% of merchandise exports)",
        "Agricultural raw materials imports (% of merchandise imports)",
        "Agriculture, forestry, and fishing, value added (% of GDP)",
        "Agriculture, forestry, and fishing, value added (annual % growth)",
        "Agriculture, forestry, and fishing, value added (constant 2015 US$)",
        "Agriculture, forestry, and fishing, value added (constant LCU)",
        "Agriculture, forestry, and fishing, value added (current LCU)",
        "Agriculture, forestry, and fishing, value added (current US$)",
        "Agriculture, forestry, and fishing, value added per worker (constant 2015 US$)",
        "Air transport, freight (million ton-km)",
        "Air transport, passengers carried",
        "Air transport, registered carrier departures worldwide",
        "Alternative and nuclear energy (% of total energy use)",
        "Annual freshwater withdrawals, agriculture (% of total freshwater withdrawal)",
        "Annual freshwater withdrawals, domestic (% of total freshwater withdrawal)",
        "Annual freshwater withdrawals, industry (% of total freshwater withdrawal)",
        "Annual freshwater withdrawals, total (% of internal resources)",
        "Annual freshwater withdrawals, total (billion cubic meters)",
        "Annualized average growth rate in per capita real survey mean consumption or income, bottom 40% of population (%)",
        "Annualized average growth rate in per capita real survey mean consumption or income, total population (%)",
        "Antiretroviral therapy coverage (% of people living with HIV)",
        "Antiretroviral therapy coverage for PMTCT (% of pregnant women living with HIV)",
        "Aquaculture production (metric tons)",
        "Arable land (% of land area)",
        "Arable land (hectares per person)",
        "Arable land (hectares)",
        "ARI treatment (% of children under 5 taken to a health provider)",
        "Armed forces personnel (% of total labor force)",
        "Armed forces personnel, total",
        "Arms exports (SIPRI trend indicator values)",
        "Arms imports (SIPRI trend indicator values)",
        "Automated teller machines (ATMs) (per 100,000 adults)",
        "Average precipitation in depth (mm per year)",
        "Average time to clear exports through customs (days)",
        "Average transaction cost of sending remittances from a specific country (%)",
        "Average transaction cost of sending remittances to a specific country (%)",
        "Average working hours of children, study and work, ages 7-14 (hours per week)",
        "Average working hours of children, study and work, female, ages 7-14 (hours per week)",
        "Average working hours of children, study and work, male, ages 7-14 (hours per week)",
        "Average working hours of children, working only, ages 7-14 (hours per week)",
        "Average working hours of children, working only, female, ages 7-14 (hours per week)",
        "Average working hours of children, working only, male, ages 7-14 (hours per week)",
        "Bank capital to assets ratio (%)",
        "Bank liquid reserves to bank assets ratio (%)",
        "Bank nonperforming loans to total gross loans (%)",
        "Battle-related deaths (number of people)",
        "Benefit incidence of social insurance programs to poorest quintile (% of total social insurance benefits)",
        "Benefit incidence of social protection and labor programs to poorest quintile (% of total SPL benefits)",
        "Benefit incidence of social safety net programs to poorest quintile (% of total safety net benefits)",
        "Benefit incidence of unemployment benefits and ALMP to poorest quintile (% of total U/ALMP benefits)",
        "Binding coverage, all products (%)",
        "Binding coverage, manufactured products (%)",
        "Binding coverage, primary products (%)",
        "Bird species, threatened",
        "Birth rate, crude (per 1,000 people)",
        "Births attended by skilled health staff (% of total)",
        "Borrowers from commercial banks (per 1,000 adults)",
        "Bound rate, simple mean, all products (%)",
        "Bound rate, simple mean, manufactured products (%)",
        "Bound rate, simple mean, primary products (%)",
        "Bribery incidence (% of firms experiencing at least one bribe payment request)",
        "Broad money (% of GDP)",
        "Broad money (current LCU)",
        "Broad money growth (annual %)",
        "Broad money to total reserves ratio",
        "Business extent of disclosure index (0=less disclosure to 10=more disclosure)",
        "Capture fisheries production (metric tons)",
        "Cause of death, by communicable diseases and maternal, prenatal and nutrition conditions (% of total)",
        "Cause of death, by injury (% of total)",
        "Cause of death, by non-communicable diseases (% of total)",
        "Central government debt, total (% of GDP)",
        "Central government debt, total (current LCU)",
        "Cereal production (metric tons)",
        "Cereal yield (kg per hectare)",
        "Changes in inventories (constant LCU)",
        "Changes in inventories (current LCU)",
        "Changes in inventories (current US$)",
        "Charges for the use of intellectual property, payments (BoP, current US$)",
        "Charges for the use of intellectual property, receipts (BoP, current US$)",
        "Chemicals (% of value added in manufacturing)",
        "Child employment in agriculture (% of economically active children ages 7-14)",
        "Child employment in agriculture, female (% of female economically active children ages 7-14)",
        "Child employment in agriculture, male (% of male economically active children ages 7-14)",
        "Child employment in manufacturing (% of economically active children ages 7-14)",
        "Child employment in manufacturing, female (% of female economically active children ages 7-14)",
        "Child employment in manufacturing, male (% of male economically active children ages 7-14)",
        "Child employment in services (% of economically active children ages 7-14)",
        "Child employment in services, female (% of female economically active children ages 7-14)",
        "Child employment in services, male (% of male economically active children ages 7-14)",
        "Children (0-14) living with HIV",
        "Children (ages 0-14) newly infected with HIV",
        "Children in employment, female (% of female children ages 7-14)",
        "Children in employment, male (% of male children ages 7-14)",
        "Children in employment, self-employed (% of children in employment, ages 7-14)",
        "Children in employment, self-employed, female (% of female children in employment, ages 7-14)",
        "Children in employment, self-employed, male (% of male children in employment, ages 7-14)",
        "Children in employment, study and work (% of children in employment, ages 7-14)",
        "Children in employment, study and work, female (% of female children in employment, ages 7-14)",
        "Children in employment, study and work, male (% of male children in employment, ages 7-14)",
        "Children in employment, total (% of children ages 7-14)",
        "Children in employment, unpaid family workers (% of children in employment, ages 7-14)",
        "Children in employment, unpaid family workers, female (% of female children in employment, ages 7-14)",
        "Children in employment, unpaid family workers, male (% of male children in employment, ages 7-14)",
        "Children in employment, wage workers (% of children in employment, ages 7-14)",
        "Children in employment, wage workers, female (% of female children in employment, ages 7-14)",
        "Children in employment, wage workers, male (% of male children in employment, ages 7-14)",
        "Children in employment, work only (% of children in employment, ages 7-14)",
        "Children in employment, work only, female (% of female children in employment, ages 7-14)",
        "Children in employment, work only, male (% of male children in employment, ages 7-14)",
        "Children out of school (% of primary school age)",
        "Children out of school, female (% of female primary school age)",
        "Children out of school, male (% of male primary school age)",
        "Children out of school, primary",
        "Children out of school, primary, female",
        "Children out of school, primary, male",
        "Children with fever receiving antimalarial drugs (% of children under age 5 with fever)",
        "Claims on central government (annual growth as % of broad money)",
        "Claims on central government, etc. (% GDP)",
        "Claims on other sectors of the domestic economy (% of GDP)",
        "Claims on other sectors of the domestic economy (annual growth as % of broad money)",
        "Claims on private sector (annual growth as % of broad money)",
        "CO2 emissions (kg per 2015 US$ of GDP)",
        "CO2 emissions (kg per 2021 PPP $ of GDP)",
        "CO2 emissions (kg per PPP $ of GDP)",
        "CO2 emissions (kt)",
        "CO2 emissions (metric tons per capita)",
        "CO2 emissions from electricity and heat production, total (% of total fuel combustion)",
        "CO2 emissions from gaseous fuel consumption (% of total)",
        "CO2 emissions from gaseous fuel consumption (kt)",
        "CO2 emissions from liquid fuel consumption (% of total)",
        "CO2 emissions from liquid fuel consumption (kt)",
        "CO2 emissions from manufacturing industries and construction (% of total fuel combustion)",
        "CO2 emissions from other sectors, excluding residential buildings and commercial and public services (% of total fuel combustion)",
        "CO2 emissions from residential buildings and commercial and public services (% of total fuel combustion)",
        "CO2 emissions from solid fuel consumption (% of total)",
        "CO2 emissions from solid fuel consumption (kt)",
        "CO2 emissions from transport (% of total fuel combustion)",
        "CO2 intensity (kg per kg of oil equivalent energy use)",
        "Coal rents (% of GDP)",
        "Combustible renewables and waste (% of total energy)",
        "Commercial bank branches (per 100,000 adults)",
        "Commercial banks and other lending (PPG + PNG) (NFL, current US$)",
        "Commercial service exports (current US$)",
        "Commercial service imports (current US$)",
        "Communications, computer, etc. (% of service exports, BoP)",
        "Communications, computer, etc. (% of service imports, BoP)",
        "Community health workers (per 1,000 people)",
        "Compensation of employees (% of expense)",
        "Compensation of employees (current LCU)",
        "Completeness of birth registration (%)",
        "Completeness of birth registration, female (%)",
        "Completeness of birth registration, male (%)",
        "Completeness of birth registration, rural (%)",
        "Completeness of birth registration, urban (%)",
        "Completeness of death registration with cause-of-death information (%)",
        "Compulsory education, duration (years)",
        "Computer, communications and other services (% of commercial service exports)",
        "Computer, communications and other services (% of commercial service imports)",
        "Condom use, population ages 15-24, female (% of females ages 15-24)",
        "Condom use, population ages 15-24, male (% of males ages 15-24)",
        "Consumer price index (2010 = 100)",
        "Consumption of iodized salt (% of households)",
        "Container port traffic (TEU: 20 foot equivalent units)",
        "Contraceptive prevalence, any method (% of married women ages 15-49)",
        "Contraceptive prevalence, any modern method (% of married women ages 15-49)",
        "Contributing family workers, female (% of female employment) (modeled ILO estimate)",
        "Contributing family workers, male (% of male employment) (modeled ILO estimate)",
        "Contributing family workers, total (% of total employment) (modeled ILO estimate)",
        "Control of Corruption: Estimate",
        "Control of Corruption: Number of Sources",
        "Control of Corruption: Percentile Rank",
        "Control of Corruption: Percentile Rank, Lower Bound of 90% Confidence Interval",
        "Control of Corruption: Percentile Rank, Upper Bound of 90% Confidence Interval",
        "Control of Corruption: Standard Error",
        "Cost of business start-up procedures (% of GNI per capita)",
        "Cost of business start-up procedures, female (% of GNI per capita)",
        "Cost of business start-up procedures, male (% of GNI per capita)",
        "Cost to export, border compliance (US$)",
        "Cost to export, documentary compliance (US$)",
        "Cost to import, border compliance (US$)",
        "Cost to import, documentary compliance (US$)",
        "Coverage of social insurance programs (% of population)",
        "Coverage of social insurance programs in 2nd quintile (% of population)",
        "Coverage of social insurance programs in 3rd quintile (% of population)",
        "Coverage of social insurance programs in 4th quintile (% of population)",
        "Coverage of social insurance programs in poorest quintile (% of population)",
        "Coverage of social insurance programs in richest quintile (% of population)",
        "Coverage of social protection and labor programs (% of population)",
        "Coverage of social safety net programs (% of population)",
        "Coverage of social safety net programs in 2nd quintile (% of population)",
        "Coverage of social safety net programs in 3rd quintile (% of population)",
        "Coverage of social safety net programs in 4th quintile (% of population)",
        "Coverage of social safety net programs in poorest quintile (% of population)",
        "Coverage of social safety net programs in richest quintile (% of population)",
        "Coverage of unemployment benefits and ALMP (% of population)",
        "Coverage of unemployment benefits and ALMP in 2nd quintile (% of population)",
        "Coverage of unemployment benefits and ALMP in 3rd quintile (% of population)",
        "Coverage of unemployment benefits and ALMP in 4th quintile (% of population)",
        "Coverage of unemployment benefits and ALMP in poorest quintile (% of population)",
        "Coverage of unemployment benefits and ALMP in richest quintile (% of population)",
        "CPIA building human resources rating (1=low to 6=high)",
        "CPIA business regulatory environment rating (1=low to 6=high)",
        "CPIA debt policy rating (1=low to 6=high)",
        "CPIA economic management cluster average (1=low to 6=high)",
        "CPIA efficiency of revenue mobilization rating (1=low to 6=high)",
        "CPIA equity of public resource use rating (1=low to 6=high)",
        "CPIA financial sector rating (1=low to 6=high)",
        "CPIA fiscal policy rating (1=low to 6=high)",
        "CPIA gender equality rating (1=low to 6=high)",
        "CPIA macroeconomic management rating (1=low to 6=high)",
        "CPIA policies for social inclusion/equity cluster average (1=low to 6=high)",
        "CPIA policy and institutions for environmental sustainability rating (1=low to 6=high)",
        "CPIA property rights and rule-based governance rating (1=low to 6=high)",
        "CPIA public sector management and institutions cluster average (1=low to 6=high)",
        "CPIA quality of budgetary and financial management rating (1=low to 6=high)",
        "CPIA quality of public administration rating (1=low to 6=high)",
        "CPIA social protection rating (1=low to 6=high)",
        "CPIA structural policies cluster average (1=low to 6=high)",
        "CPIA trade rating (1=low to 6=high)",
        "CPIA transparency, accountability, and corruption in the public sector rating (1=low to 6=high)",
        "Crop production index (2014-2016 = 100)",
        "Current account balance (% of GDP)",
        "Current account balance (BoP, current US$)",
        "Current education expenditure, primary (% of total expenditure in primary public institutions)",
        "Current education expenditure, secondary (% of total expenditure in secondary public institutions)",
        "Current education expenditure, tertiary (% of total expenditure in tertiary public institutions)",
        "Current education expenditure, total (% of total expenditure in public institutions)",
        "Current health expenditure (% of GDP)",
        "Current health expenditure per capita (current US$)",
        "Current health expenditure per capita, PPP (current international $)",
        "Customs and other import duties (% of tax revenue)",
        "Customs and other import duties (current LCU)",
        "Death rate, crude (per 1,000 people)",
        "Debt service (PPG and IMF only, % of exports of goods, services and primary income)",
        "Debt service on external debt, public and publicly guaranteed (PPG) (TDS, current US$)",
        "Debt service on external debt, total (TDS, current US$)",
        "DEC alternative conversion factor (LCU per US$)",
        "Demand for family planning satisfied by modern methods (% of married women with demand for family planning)",
        "Deposit interest rate (%)",
        "Depositors with commercial banks (per 1,000 adults)",
        "Depth of credit information index (0=low to 8=high)",
        "Diabetes prevalence (% of population ages 20 to 79)",
        "Diarrhea treatment (% of children under 5 receiving oral rehydration and continued feeding)",
        "Diarrhea treatment (% of children under 5 who received ORS packet)",
        "Disaster risk reduction progress score (1-5 scale; 5=best)",
        "Discrepancy in expenditure estimate of GDP (constant LCU)",
        "Discrepancy in expenditure estimate of GDP (current LCU)",
        "Domestic credit provided by financial sector (% of GDP)",
        "Domestic credit to private sector (% of GDP)",
        "Domestic credit to private sector by banks (% of GDP)",
        "Domestic general government health expenditure (% of current health expenditure)",
        "Domestic general government health expenditure (% of GDP)",
        "Domestic general government health expenditure (% of general government expenditure)",
        "Domestic general government health expenditure per capita (current US$)",
        "Domestic general government health expenditure per capita, PPP (current international $)",
        "Domestic private health expenditure (% of current health expenditure)",
        "Domestic private health expenditure per capita (current US$)",
        "Domestic private health expenditure per capita, PPP (current international $)",
        "Droughts, floods, extreme temperatures (% of population, average 1990-2009)",
        "Ease of doing business rank (1=most business-friendly regulations)",
        "Ease of doing business score (0 = lowest performance to 100 = best performance)",
        "Educational attainment, at least Bachelor's or equivalent, population 25+, female (%) (cumulative)",
        "Educational attainment, at least Bachelor's or equivalent, population 25+, male (%) (cumulative)",
        "Educational attainment, at least Bachelor's or equivalent, population 25+, total (%) (cumulative)",
        "Educational attainment, at least completed lower secondary, population 25+, female (%) (cumulative)",
        "Educational attainment, at least completed lower secondary, population 25+, male (%) (cumulative)",
        "Educational attainment, at least completed lower secondary, population 25+, total (%) (cumulative)",
        "Educational attainment, at least completed post-secondary, population 25+, female (%) (cumulative)",
        "Educational attainment, at least completed post-secondary, population 25+, male (%) (cumulative)",
        "Educational attainment, at least completed post-secondary, population 25+, total (%) (cumulative)",
        "Educational attainment, at least completed primary, population 25+ years, female (%) (cumulative)",
        "Educational attainment, at least completed primary, population 25+ years, male (%) (cumulative)",
        "Educational attainment, at least completed primary, population 25+ years, total (%) (cumulative)",
        "Educational attainment, at least completed short-cycle tertiary, population 25+, female (%) (cumulative)",
        "Educational attainment, at least completed short-cycle tertiary, population 25+, male (%) (cumulative)",
        "Educational attainment, at least completed short-cycle tertiary, population 25+, total (%) (cumulative)",
        "Educational attainment, at least completed upper secondary, population 25+, female (%) (cumulative)",
        "Educational attainment, at least completed upper secondary, population 25+, male (%) (cumulative)",
        "Educational attainment, at least completed upper secondary, population 25+, total (%) (cumulative)",
        "Educational attainment, at least Master's or equivalent, population 25+, female (%) (cumulative)",
        "Educational attainment, at least Master's or equivalent, population 25+, male (%) (cumulative)",
        "Educational attainment, at least Master's or equivalent, population 25+, total (%) (cumulative)",
        "Educational attainment, Doctoral or equivalent, population 25+, female (%) (cumulative)",
        "Educational attainment, Doctoral or equivalent, population 25+, male (%) (cumulative)",
        "Educational attainment, Doctoral or equivalent, population 25+, total (%) (cumulative)",
        "Electric power consumption (kWh per capita)",
        "Electric power transmission and distribution losses (% of output)",
        "Electricity production from coal sources (% of total)",
        "Electricity production from hydroelectric sources (% of total)",
        "Electricity production from natural gas sources (% of total)",
        "Electricity production from nuclear sources (% of total)",
        "Electricity production from oil sources (% of total)",
        "Electricity production from oil, gas and coal sources (% of total)",
        "Electricity production from renewable sources, excluding hydroelectric (% of total)",
        "Electricity production from renewable sources, excluding hydroelectric (kWh)",
        "Employers, female (% of female employment) (modeled ILO estimate)",
        "Employers, male (% of male employment) (modeled ILO estimate)",
        "Employers, total (% of total employment) (modeled ILO estimate)",
        "Employment in agriculture (% of total employment) (modeled ILO estimate)",
        "Employment in agriculture, female (% of female employment) (modeled ILO estimate)",
        "Employment in agriculture, male (% of male employment) (modeled ILO estimate)",
        "Employment in industry (% of total employment) (modeled ILO estimate)",
        "Employment in industry, female (% of female employment) (modeled ILO estimate)",
        "Employment in industry, male (% of male employment) (modeled ILO estimate)",
        "Employment in services (% of total employment) (modeled ILO estimate)",
        "Employment in services, female (% of female employment) (modeled ILO estimate)",
        "Employment in services, male (% of male employment) (modeled ILO estimate)",
        "Employment to population ratio, 15+, female (%) (modeled ILO estimate)",
        "Employment to population ratio, 15+, female (%) (national estimate)",
        "Employment to population ratio, 15+, male (%) (modeled ILO estimate)",
        "Employment to population ratio, 15+, male (%) (national estimate)",
        "Employment to population ratio, 15+, total (%) (modeled ILO estimate)",
        "Employment to population ratio, 15+, total (%) (national estimate)",
        "Employment to population ratio, ages 15-24, female (%) (modeled ILO estimate)",
        "Employment to population ratio, ages 15-24, female (%) (national estimate)",
        "Employment to population ratio, ages 15-24, male (%) (modeled ILO estimate)",
        "Employment to population ratio, ages 15-24, male (%) (national estimate)",
        "Employment to population ratio, ages 15-24, total (%) (modeled ILO estimate)",
        "Employment to population ratio, ages 15-24, total (%) (national estimate)",
        "Energy imports, net (% of energy use)",
        "Energy intensity level of primary energy (MJ/$2017 PPP GDP)",
        "Energy related methane emissions (% of total)",
        "Energy use (kg of oil equivalent per capita)",
        "Energy use (kg of oil equivalent) per $1,000 GDP (constant 2021 PPP)",
        "Exclusive breastfeeding (% of children under 6 months)",
        "Expenditure on primary education (% of government expenditure on education)",
        "Expenditure on secondary education (% of government expenditure on education)",
        "Expenditure on tertiary education (% of government expenditure on education)",
        "Expense (% of GDP)",
        "Expense (current LCU)",
        "Export unit value index (2015 = 100)",
        "Export value index (2015 = 100)",
        "Export volume index (2015 = 100)",
        "Exports as a capacity to import (constant LCU)",
        "Exports of goods and services (% of GDP)",
        "Exports of goods and services (annual % growth)",
        "Exports of goods and services (BoP, current US$)",
        "Exports of goods and services (constant 2015 US$)",
        "Exports of goods and services (constant LCU)",
        "Exports of goods and services (current LCU)",
        "Exports of goods and services (current US$)",
        "Exports of goods, services and primary income (BoP, current US$)",
        "External balance on goods and services (% of GDP)",
        "External balance on goods and services (constant LCU)",
        "External balance on goods and services (current LCU)",
        "External balance on goods and services (current US$)",
        "External debt stocks (% of GNI)",
        "External debt stocks, long-term (DOD, current US$)",
        "External debt stocks, private nonguaranteed (PNG) (DOD, current US$)",
        "External debt stocks (% of GNI)",
        "External debt stocks, long-term (DOD, current US$)",
        "External debt stocks, private nonguaranteed (PNG) (DOD, current US$)",
        "External debt stocks, public and publicly guaranteed (PPG) (DOD, current US$)",
        "External debt stocks, short-term (DOD, current US$)",
        "External debt stocks, total (DOD, current US$)",
        "External health expenditure (% of current health expenditure)",
        "External health expenditure per capita (current US$)",
        "External health expenditure per capita, PPP (current international $)",
        "Female genital mutilation prevalence (%)",
        "Female headed households (% of households with a female head)",
        "Female primary school age children out-of-school (%)",
        "Female pupils below minimum reading proficiency at end of primary (%). Low GAML threshold",
        "Female share of employment in senior and middle management (%)",
        "Fertility rate, total (births per woman)",
        "Fertilizer consumption (% of fertilizer production)",
        "Fertilizer consumption (kilograms per hectare of arable land)",
        "Final consumption expenditure (% of GDP)",
        "Final consumption expenditure (annual % growth)",
        "Final consumption expenditure (constant 2015 US$)",
        "Final consumption expenditure (constant LCU)",
        "Final consumption expenditure (current LCU)",
        "Final consumption expenditure (current US$)",
        "Financial intermediary services indirectly Measured (FISIM) (constant LCU)",
        "Financial intermediary services indirectly Measured (FISIM) (current LCU)",
        "Firms competing against unregistered firms (% of firms)",
        "Firms expected to give gifts in meetings with tax officials (% of firms)",
        "Firms experiencing electrical outages (% of firms)",
        "Firms experiencing losses due to theft and vandalism (% of firms)",
        "Firms formally registered when operations started (% of firms)",
        "Firms offering formal training (% of firms)",
        "Firms that do not report all sales for tax purposes (% of firms)",
        "Firms that spend on R&D (% of firms)",
        "Firms using banks to finance investment (% of firms)",
        "Firms using banks to finance working capital (% of firms)",
        "Firms visited or required meetings with tax officials (% of firms)",
        "Firms with female participation in ownership (% of firms)",
        "Firms with female top manager (% of firms)",
        "Fish species, threatened",
        "Fixed broadband subscriptions",
        "Fixed broadband subscriptions (per 100 people)",
        "Fixed telephone subscriptions",
        "Fixed telephone subscriptions (per 100 people)",
        "Food exports (% of merchandise exports)",
        "Food imports (% of merchandise imports)",
        "Food production index (2014-2016 = 100)",
        "Food, beverages and tobacco (% of value added in manufacturing)",
        "Foreign direct investment, net (BoP, current US$)",
        "Foreign direct investment, net inflows (% of GDP)",
        "Foreign direct investment, net inflows (BoP, current US$)",
        "Foreign direct investment, net outflows (% of GDP)",
        "Foreign direct investment, net outflows (BoP, current US$)",
        "Forest area (% of land area)",
        "Forest area (sq. km)",
        "Forest rents (% of GDP)",
        "Fossil fuel energy consumption (% of total)",
        "Fuel exports (% of merchandise exports)",
        "Fuel imports (% of merchandise imports)",
        "GDP (constant 2015 US$)",
        "GDP (constant LCU)",
        "GDP (current LCU)",
        "GDP (current US$)",
        "GDP deflator (base year varies by country)",
        "GDP deflator: linked series (base year varies by country)",
        "GDP growth (annual %)",
        "GDP per capita (constant 2015 US$)",
        "GDP per capita (constant LCU)",
        "GDP per capita (current LCU)",
        "GDP per capita (current US$)",
        "GDP per capita growth (annual %)",
        "GDP per capita, PPP (constant 2021 international $)",
        "GDP per capita, PPP (current international $)",
        "GDP per person employed (constant 2021 PPP $)",
        "GDP per unit of energy use (constant 2021 PPP $ per kg of oil equivalent)",
        "GDP per unit of energy use (PPP $ per kg of oil equivalent)",
        "GDP, PPP (constant 2021 international $)",
        "GDP, PPP (current international $)",
        "GDP: linked series (current LCU)",
        "General government final consumption expenditure (% of GDP)",
        "General government final consumption expenditure (annual % growth)",
        "General government final consumption expenditure (constant 2015 US$)",
        "General government final consumption expenditure (constant LCU)",
        "General government final consumption expenditure (current LCU)",
        "General government final consumption expenditure (current US$)",
        "GHG net emissions/removals by LUCF (Mt of CO2 equivalent)",
        "Gini index",
        "GNI (constant 2015 US$)",
        "GNI (constant LCU)",
        "GNI (current LCU)",
        "GNI (current US$)",
        "GNI growth (annual %)",
        "GNI per capita (constant 2015 US$)",
        "GNI per capita (constant LCU)",
        "GNI per capita (current LCU)",
        "GNI per capita growth (annual %)",
        "GNI per capita, Atlas method (current US$)",
        "GNI per capita, PPP (constant 2021 international $)",
        "GNI per capita, PPP (current international $)",
        "GNI, Atlas method (current US$)",
        "GNI, PPP (constant 2021 international $)",
        "GNI, PPP (current international $)",
        "GNI: linked series (current LCU)",
        "Goods and services expense (% of expense)",
        "Goods and services expense (current LCU)",
        "Goods exports (BoP, current US$)",
        "Goods imports (BoP, current US$)",
        "Government Effectiveness: Estimate",
        "Government Effectiveness: Number of Sources",
        "Government Effectiveness: Percentile Rank",
        "Government Effectiveness: Percentile Rank, Lower Bound of 90% Confidence Interval",
        "Government Effectiveness: Percentile Rank, Upper Bound of 90% Confidence Interval",
        "Government Effectiveness: Standard Error",
        "Government expenditure on education, total (% of GDP)",
        "Government expenditure on education, total (% of government expenditure)",
        "Government expenditure per student, primary (% of GDP per capita)",
        "Government expenditure per student, secondary (% of GDP per capita)",
        "Government expenditure per student, tertiary (% of GDP per capita)",
        "Grants and other revenue (% of revenue)",
        "Grants and other revenue (current LCU)",
        "Grants, excluding technical cooperation (BoP, current US$)",
        "Gross capital formation (% of GDP)",
        "Gross capital formation (annual % growth)",
        "Gross capital formation (constant 2015 US$)",
        "Gross capital formation (constant LCU)",
        "Gross capital formation (current LCU)",
        "Gross capital formation (current US$)",
        "Gross domestic income (constant LCU)",
        "Gross domestic savings (% of GDP)",
        "Gross domestic savings (current LCU)",
        "Gross domestic savings (current US$)",
        "Gross fixed capital formation (% of GDP)",
        "Gross fixed capital formation (annual % growth)",
        "Gross fixed capital formation (constant 2015 US$)",
        "Gross fixed capital formation (constant LCU)",
        "Gross fixed capital formation (current LCU)",
        "Gross fixed capital formation (current US$)",
        "Gross fixed capital formation, private sector (% of GDP)",
        "Gross fixed capital formation, private sector (current LCU)",
        "Gross intake ratio in first grade of primary education, female (% of relevant age group)",
        "Gross intake ratio in first grade of primary education, male (% of relevant age group)",
        "Gross intake ratio in first grade of primary education, total (% of relevant age group)",
        "Gross national expenditure (% of GDP)",
        "Gross national expenditure (constant 2015 US$)",
        "Gross national expenditure (constant LCU)",
        "Gross national expenditure (current LCU)",
        "Gross national expenditure (current US$)",
        "Gross national expenditure deflator (base year varies by country)",
        "Gross savings (% of GDP)",
        "Gross savings (% of GNI)",
        "Gross savings (current LCU)",
        "Gross savings (current US$)",
        "Gross value added at basic prices (GVA) (constant 2015 US$)",
        "Gross value added at basic prices (GVA) (constant LCU)",
        "Gross value added at basic prices (GVA) (current LCU)",
        "Gross value added at basic prices (GVA) (current US$)",
        "HFC gas emissions (thousand metric tons of CO2 equivalent)",
        "High-technology exports (% of manufactured exports)",
        "High-technology exports (current US$)",
        "Hospital beds (per 1,000 people)",
        "Households and NPISHs final consumption expenditure (% of GDP)",
        "Households and NPISHs Final consumption expenditure (annual % growth)",
        "Households and NPISHs Final consumption expenditure (constant 2015 US$)",
        "Households and NPISHs Final consumption expenditure (constant LCU)",
        "Households and NPISHs Final consumption expenditure (current LCU)",
        "Households and NPISHs Final consumption expenditure (current US$)",
        "Households and NPISHs Final consumption expenditure per capita (constant 2015 US$)",
        "Households and NPISHs Final consumption expenditure per capita growth (annual %)",
        "Households and NPISHs Final consumption expenditure, PPP (constant 2021 international $)",
        "Households and NPISHs Final consumption expenditure, PPP (current international $)",
        "Households and NPISHs final consumption expenditure: linked series (current LCU)",
        "Human capital index (HCI) (scale 0-1)",
        "Human capital index (HCI), female (scale 0-1)",
        "Human capital index (HCI), female, lower bound (scale 0-1)",
        "Human capital index (HCI), female, upper bound (scale 0-1)",
        "Human capital index (HCI), lower bound (scale 0-1)",
        "Human capital index (HCI), male (scale 0-1)",
        "Human capital index (HCI), male, lower bound (scale 0-1)",
        "Human capital index (HCI), male, upper bound (scale 0-1)",
        "Human capital index (HCI), upper bound (scale 0-1)",
        "IBRD loans and IDA credits (DOD, current US$)",
        "ICT goods exports (% of total goods exports)",
        "ICT goods imports (% total goods imports)",
        "ICT service exports (% of service exports, BoP)",
        "ICT service exports (BoP, current US$)",
        "IDA resource allocation index (1=low to 6=high)",
        "IFC, private nonguaranteed (NFL, US$)",
        "IMF repurchases and charges (TDS, current US$)",
        "Immunization, DPT (% of children ages 12-23 months)",
        "Immunization, HepB3 (% of one-year-old children)",
        "Immunization, measles (% of children ages 12-23 months)",
        "Import unit value index (2015 = 100)",
        "Import value index (2015 = 100)",
        "Import volume index (2015 = 100)",
        "Imports of goods and services (% of GDP)",
        "Imports of goods and services (annual % growth)",
        "Imports of goods and services (BoP, current US$)",
        "Imports of goods and services (constant 2015 US$)",
        "Imports of goods and services (constant LCU)",
        "Imports of goods and services (current LCU)",
        "Imports of goods and services (current US$)",
        "Imports of goods, services and primary income (BoP, current US$)",
        "Incidence of HIV, ages 15-24 (per 1,000 uninfected population ages 15-24)",
        "Incidence of HIV, ages 15-49 (per 1,000 uninfected population ages 15-49)",
        "Incidence of HIV, all (per 1,000 uninfected population)",
        "Incidence of malaria (per 1,000 population at risk)",
        "Incidence of tuberculosis (per 100,000 people)",
        "Income share held by fourth 20%",
        "Income share held by highest 10%",
        "Income share held by highest 20%",
        "Income share held by lowest 10%",
        "Income share held by lowest 20%",
        "Income share held by second 20%",
        "Income share held by third 20%",
        "Individuals using the Internet (% of population)",
        "Industrial design applications, nonresident, by count",
        "Industrial design applications, resident, by count",
        "Industry (including construction), value added (% of GDP)",
        "Industry (including construction), value added (annual % growth)",
        "Industry (including construction), value added (constant 2015 US$)",
        "Industry (including construction), value added (constant LCU)",
        "Industry (including construction), value added (current LCU)",
        "Industry (including construction), value added (current US$)",
        "Industry (including construction), value added per worker (constant 2015 US$)",
        "Inflation, consumer prices (annual %)",
        "Inflation, GDP deflator (annual %)",
        "Inflation, GDP deflator: linked series (annual %)",
        "Informal payments to public officials (% of firms)",
        "Insurance and financial services (% of commercial service exports)",
        "Insurance and financial services (% of commercial service imports)",
        "Insurance and financial services (% of service exports, BoP)",
        "Insurance and financial services (% of service imports, BoP)",
        "Intentional homicides (per 100,000 people)",
        "Intentional homicides, female (per 100,000 female)",
        "Intentional homicides, male (per 100,000 male)",
        "Interest payments (% of expense)",
        "Interest payments (% of revenue)",
        "Interest payments (current LCU)",
        "Interest rate spread (lending rate minus deposit rate, %)",
        "Internally displaced persons, new displacement associated with conflict and violence (number of cases)",
        "Internally displaced persons, new displacement associated with disasters (number of cases)",
        "Internally displaced persons, total displaced by conflict and violence (number of people)",
        "International migrant stock (% of population)",
        "International migrant stock, total",
        "International tourism, expenditures (% of total imports)",
        "International tourism, expenditures (current US$)",
        "International tourism, expenditures for passenger transport items (current US$)",
        "International tourism, expenditures for travel items (current US$)",
        "International tourism, number of arrivals",
        "International tourism, number of departures",
        "International tourism, receipts (% of total exports)",
        "International tourism, receipts (current US$)",
        "International tourism, receipts for passenger transport items (current US$)",
        "International tourism, receipts for travel items (current US$)",
        "Investment in energy with private participation (current US$)",
        "Investment in ICT with private participation (current US$)",
        "Investment in transport with private participation (current US$)",
        "Investment in water and sanitation with private participation (current US$)",
        "Labor force participation rate for ages 15-24, female (%) (modeled ILO estimate)",
        "Labor force participation rate for ages 15-24, female (%) (national estimate)",
        "Labor force participation rate for ages 15-24, male (%) (modeled ILO estimate)",
        "Labor force participation rate for ages 15-24, male (%) (national estimate)",
        "Labor force participation rate for ages 15-24, total (%) (modeled ILO estimate)",
        "Labor force participation rate for ages 15-24, total (%) (national estimate)",
        "Labor force participation rate, female (% of female population ages 15+) (modeled ILO estimate)",
        "Labor force participation rate, female (% of female population ages 15+) (national estimate)",
        "Labor force participation rate, female (% of female population ages 15-64) (modeled ILO estimate)",
        "Labor force participation rate, male (% of male population ages 15+) (modeled ILO estimate)",
        "Labor force participation rate, male (% of male population ages 15+) (national estimate)",
        "Labor force participation rate, male (% of male population ages 15-64) (modeled ILO estimate)",
        "Labor force participation rate, total (% of total population ages 15+) (modeled ILO estimate)",
        "Labor force participation rate, total (% of total population ages 15+) (national estimate)",
        "Labor force participation rate, total (% of total population ages 15-64) (modeled ILO estimate)",
        "Labor force with advanced education (% of total working-age population with advanced education)",
        "Labor force with advanced education, female (% of female working-age population with advanced education)",
        "Labor force with advanced education, male (% of male working-age population with advanced education)",
        "Labor force with basic education (% of total working-age population with basic education)",
        "Labor force with basic education, female (% of female working-age population with basic education)",
        "Labor force with basic education, male (% of male working-age population with basic education)",
        "Labor force with intermediate education (% of total working-age population with intermediate education)",
        "Labor force with intermediate education, female (% of female working-age population with intermediate education)",
        "Labor force with intermediate education, male (% of male working-age population with intermediate education)",
        "Labor force, female (% of total labor force)",
        "Labor force, total",
        "Labor tax and contributions (% of commercial profits)",
        "Land area (sq. km)",
        "Land area where elevation is below 5 meters (% of total land area)",
        "Land under cereal production (hectares)",
        "Lead time to export, median case (days)",
        "Lead time to import, median case (days)",
        "Learning poverty: Share of Children at the End-of-Primary age below minimum reading proficiency adjusted by Out-of-School Children (%)",
        "Learning poverty: Share of Female Children at the End-of-Primary age below minimum reading proficiency adjusted by Out-of-School Children (%)",
        "Learning poverty: Share of Male Children at the End-of-Primary age below minimum reading proficiency adjusted by Out-of-School Children (%)",
        "Lending interest rate (%)",
        "Level of water stress: freshwater withdrawal as a proportion of available freshwater resources",
        "Life expectancy at birth, female (years)",
        "Life expectancy at birth, male (years)",
        "Life expectancy at birth, total (years)",
        "Lifetime risk of maternal death (%)",
        "Lifetime risk of maternal death (1 in: rate varies by country)",
        "Liner shipping connectivity index (maximum value in 2004 = 100)",
        "Listed domestic companies, total",
        "Literacy rate, adult female (% of females ages 15 and above)",
        "Literacy rate, adult male (% of males ages 15 and above)",
        "Literacy rate, adult total (% of people ages 15 and above)",
        "Literacy rate, youth (ages 15-24), gender parity index (GPI)",
        "Literacy rate, youth female (% of females ages 15-24)",
        "Literacy rate, youth male (% of males ages 15-24)",
        "Literacy rate, youth total (% of people ages 15-24)",
        "Livestock production index (2014-2016 = 100)",
        "Logistics performance index: Ability to track and trace consignments (1=low to 5=high)",
        "Logistics performance index: Competence and quality of logistics services (1=low to 5=high)",
        "Logistics performance index: Ease of arranging competitively priced shipments (1=low to 5=high)",
        "Logistics performance index: Efficiency of customs clearance process (1=low to 5=high)",
        "Logistics performance index: Frequency with which shipments reach consignee within scheduled or expected time (1=low to 5=high)",
        "Logistics performance index: Overall (1=low to 5=high)",
        "Logistics performance index: Quality of trade and transport-related infrastructure (1=low to 5=high)",
        "Losses due to theft and vandalism (% of annual sales for affected firms)",
        "Low-birthweight babies (% of births)",
        "Lower secondary completion rate, female (% of relevant age group)",
        "Lower secondary completion rate, male (% of relevant age group)",
        "Lower secondary completion rate, total (% of relevant age group)",
        "Lower secondary school starting age (years)",
        "Machinery and transport equipment (% of value added in manufacturing)",
        "Male primary school age children out-of-school (%)",
        "Male pupils below minimum reading proficiency at end of primary (%). Low GAML threshold",
        "Mammal species, threatened",
        "Manufactures exports (% of merchandise exports)",
        "Manufactures imports (% of merchandise imports)",
        "Manufacturing, value added (% of GDP)",
        "Manufacturing, value added (annual % growth)",
        "Manufacturing, value added (constant 2015 US$)",
        "Manufacturing, value added (constant LCU)",
        "Manufacturing, value added (current LCU)",
        "Manufacturing, value added (current US$)",
        "Marine protected areas (% of territorial waters)",
        "Market capitalization of listed domestic companies (% of GDP)",
        "Market capitalization of listed domestic companies (current US$)",
        "Maternal mortality ratio (modeled estimate, per 100,000 live births)",
        "Maternal mortality ratio (national estimate, per 100,000 live births)",
        "Medium and high-tech exports (% manufactured exports)",
        "Medium and high-tech manufacturing value added (% manufacturing value added)",
        "Merchandise exports (current US$)",
        "Merchandise exports by the reporting economy (current US$)",
        "Merchandise exports by the reporting economy, residual (% of total merchandise exports)",
        "Merchandise exports to economies in the Arab World (% of total merchandise exports)",
        "Merchandise exports to high-income economies (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies in East Asia & Pacific (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies in Europe & Central Asia (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies in Latin America & the Caribbean (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies in Middle East & North Africa (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies in South Asia (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies in Sub-Saharan Africa (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies outside region (% of total merchandise exports)",
        "Merchandise exports to low- and middle-income economies within region (% of total merchandise exports)",
        "Merchandise imports (current US$)",
        "Merchandise imports by the reporting economy (current US$)",
        "Merchandise imports by the reporting economy, residual (% of total merchandise imports)",
        "Merchandise imports from economies in the Arab World (% of total merchandise imports)",
        "Merchandise imports from high-income economies (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies in East Asia & Pacific (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies in Europe & Central Asia (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies in Latin America & the Caribbean (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies in Middle East & North Africa (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies in South Asia (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies in Sub-Saharan Africa (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies outside region (% of total merchandise imports)",
        "Merchandise imports from low- and middle-income economies within region (% of total merchandise imports)",
        "Merchandise trade (% of GDP)",
        "Methane emissions (% change from 1990)",
        "Methane emissions (kt of CO2 equivalent)",
        "Methane emissions in energy sector (thousand metric tons of CO2 equivalent)",
        "Methodology assessment of statistical capacity (scale 0 - 100)",
        "Military expenditure (% of GDP)",
        "Military expenditure (% of general government expenditure)",
        "Military expenditure (current LCU)",
        "Military expenditure (current USD)",
        "Mineral rents (% of GDP)",
        "Mobile cellular subscriptions",
        "Mobile cellular subscriptions (per 100 people)",
        "Monetary Sector credit to private sector (% GDP)",
        "Mortality caused by road traffic injury (per 100,000 population)",
        "Mortality from CVD, cancer, diabetes or CRD between exact ages 30 and 70 (%)",
        "Mortality from CVD, cancer, diabetes or CRD between exact ages 30 and 70, female (%)",
        "Mortality from CVD, cancer, diabetes or CRD between exact ages 30 and 70, male (%)",
        "Mortality rate attributed to household and ambient air pollution, age-standardized (per 100,000 population)",
        "Mortality rate attributed to household and ambient air pollution, age-standardized, female (per 100,000 female population)",
        "Mortality rate attributed to household and ambient air pollution, age-standardized, male (per 100,000 male population)",
        "Mortality rate attributed to unintentional poisoning (per 100,000 population)",
        "Mortality rate attributed to unintentional poisoning, female (per 100,000 female population)",
        "Mortality rate attributed to unintentional poisoning, male (per 100,000 male population)",
        "Mortality rate attributed to unsafe water, unsafe sanitation and lack of hygiene (per 100,000 population)",
        "Mortality rate, adult, female (per 1,000 female adults)",
        "Mortality rate, adult, male (per 1,000 male adults)",
        "Mortality rate, infant (per 1,000 live births)",
        "Mortality rate, infant, female (per 1,000 live births)",
        "Mortality rate, infant, male (per 1,000 live births)",
        "Mortality rate, neonatal (per 1,000 live births)",
        "Mortality rate, under-5 (per 1,000 live births)",
        "Mortality rate, under-5, female (per 1,000 live births)",
        "Mortality rate, under-5, male (per 1,000 live births)",
        "Multidimensional poverty headcount ratio (UNDP) (% of population)",
        "Multidimensional poverty headcount ratio (World Bank) (% of population)",
        "Multilateral debt service (% of public and publicly guaranteed debt service)",
        "Multilateral debt service (TDS, current US$)",
        "Natural gas rents (% of GDP)",
        "Net acquisition of financial assets (% of GDP)",
        "Net acquisition of financial assets (current LCU)",
        "Net barter terms of trade index (2015 = 100)",
        "Net bilateral aid flows from DAC donors, Australia (current US$)",
        "Net bilateral aid flows from DAC donors, Austria (current US$)",
        "Net bilateral aid flows from DAC donors, Belgium (current US$)",
        "Net bilateral aid flows from DAC donors, Canada (current US$)",
        "Net bilateral aid flows from DAC donors, Czechia (current US$)",
        "Net bilateral aid flows from DAC donors, Denmark (current US$)",
        "Net bilateral aid flows from DAC donors, Estonia (current US$)",
        "Net bilateral aid flows from DAC donors, European Union institutions (current US$)",
        "Net bilateral aid flows from DAC donors, Finland (current US$)",
        "Net bilateral aid flows from DAC donors, France (current US$)",
        "Net bilateral aid flows from DAC donors, Germany (current US$)",
        "Net bilateral aid flows from DAC donors, Greece (current US$)",
        "Net bilateral aid flows from DAC donors, Hungary (current US$)",
        "Net bilateral aid flows from DAC donors, Iceland (current US$)",
        "Net bilateral aid flows from DAC donors, Ireland (current US$)",
        "Net bilateral aid flows from DAC donors, Italy (current US$)",
        "Net bilateral aid flows from DAC donors, Japan (current US$)",
        "Net bilateral aid flows from DAC donors, Korea, Rep. (current US$)",
        "Net bilateral aid flows from DAC donors, Lithuania (current US$)",
        "Net bilateral aid flows from DAC donors, Luxembourg (current US$)",
        "Net bilateral aid flows from DAC donors, Netherlands (current US$)",
        "Net bilateral aid flows from DAC donors, New Zealand (current US$)",
        "Net bilateral aid flows from DAC donors, Norway (current US$)",
        "Net bilateral aid flows from DAC donors, Poland (current US$)",
        "Net bilateral aid flows from DAC donors, Portugal (current US$)",
        "Net bilateral aid flows from DAC donors, Slovak Republic (current US$)",
        "Net bilateral aid flows from DAC donors, Slovenia (current US$)",
        "Net bilateral aid flows from DAC donors, Spain (current US$)",
        "Net bilateral aid flows from DAC donors, Sweden (current US$)",
        "Net bilateral aid flows from DAC donors, Switzerland (current US$)",
        "Net bilateral aid flows from DAC donors, Total (current US$)",
        "Net bilateral aid flows from DAC donors, United Kingdom (current US$)",
        "Net bilateral aid flows from DAC donors, United States (current US$)",
        "Net capital account (BoP, current US$)",
        "Net domestic credit (current LCU)",
        "Net errors and omissions (BoP, current US$)",
        "Net financial account (BoP, current US$)",
        "Net financial flows, bilateral (NFL, current US$)",
        "Net financial flows, IBRD (NFL, current US$)",
        "Net financial flows, IDA (NFL, current US$)",
        "Net financial flows, IMF concessional (NFL, current US$)",
        "Net financial flows, IMF nonconcessional (NFL, current US$)",
        "Net financial flows, multilateral (NFL, current US$)",
        "Net financial flows, others (NFL, current US$)",
        "Net financial flows, RDB concessional (NFL, current US$)",
        "Net financial flows, RDB nonconcessional (NFL, current US$)",
        "Net flows on external debt, private nonguaranteed (PNG) (NFL, current US$)",
        "Net flows on external debt, private nonguaranteed (PNG) (NFL, current US$)",
        "Net foreign assets (current LCU)",
        "Net incurrence of liabilities, total (% of GDP)",
        "Net incurrence of liabilities, total (current LCU)",
        "Net intake rate in grade 1 (% of official school-age population)",
        "Net intake rate in grade 1, female (% of official school-age population)",
        "Net intake rate in grade 1, male (% of official school-age population)",
        "Net investment in nonfinancial assets (% of GDP)",
        "Net investment in nonfinancial assets (current LCU)",
        "Net lending (+) / net borrowing (-) (% of GDP)",
        "Net lending (+) / net borrowing (-) (current LCU)",
        "Net migration",
        "Net ODA provided to the least developed countries (% of GNI)",
        "Net ODA provided, to the least developed countries (current US$)",
        "Net ODA provided, total (% of GNI)",
        "Net ODA provided, total (constant 2021 US$)",
        "Net ODA provided, total (current US$)",
        "Net ODA received (% of central government expense)",
        "Net ODA received (% of GNI)",
        "Net ODA received (% of gross capital formation)",
        "Net ODA received (% of imports of goods, services and primary income)",
        "Net ODA received per capita (current US$)",
        "Net official aid received (constant 2021 US$)",
        "Net official aid received (current US$)",
        "Net official development assistance and official aid received (constant 2021 US$)",
        "Net official development assistance and official aid received (current US$)",
        "Net official development assistance received (constant 2021 US$)",
        "Net official development assistance received (current US$)",
        "Net official flows from UN agencies, CERF (current US$)",
        "Net official flows from UN agencies, FAO (current US$)",
        "Net official flows from UN agencies, IAEA (current US$)",
        "Net official flows from UN agencies, IFAD (current US$)",
        "Net official flows from UN agencies, ILO (current US$)",
        "Net official flows from UN agencies, SDGFUND (current US$)",
        "Net official flows from UN agencies, SPRP (current US$)",
        "Net official flows from UN agencies, UNAIDS (current US$)",
        "Net official flows from UN agencies, UNCDF (current US$)",
        "Net official flows from UN agencies, UNCOVID (current US$)",
        "Net official flows from UN agencies, UNCTAD (current US$)",
        "Net official flows from UN agencies, UNDP (current US$)",
        "Net official flows from UN agencies, UNECE (current US$)",
        "Net official flows from UN agencies, UNEP (current US$)",
        "Net official flows from UN agencies, UNFPA (current US$)",
        "Net official flows from UN agencies, UNHCR (current US$)",
        "Net official flows from UN agencies, UNICEF (current US$)",
        "Net official flows from UN agencies, UNIDIR (current US$)",
        "Net official flows from UN agencies, UNIDO (current US$)",
        "Net official flows from UN agencies, UNPBF (current US$)",
        "Net official flows from UN agencies, UNRWA (current US$)",
        "Net official flows from UN agencies, UNTA (current US$)",
        "Net official flows from UN agencies, UNWOMEN (current US$)",
        "Net official flows from UN agencies, UNWTO (current US$)",
        "Net official flows from UN agencies, WFP (current US$)",
        "Net official flows from UN agencies, WHO (current US$)",
        "Net official flows from UN agencies, WTO-ITC (current US$)",
        "Net primary income (BoP, current US$)",
        "Net primary income (Net income from abroad) (constant LCU)",
        "Net primary income (Net income from abroad) (current LCU)",
        "Net primary income (Net income from abroad) (current US$)",
        "Net secondary income (BoP, current US$)",
        "Net secondary income (Net current transfers from abroad) (constant LCU)",
        "Net secondary income (Net current transfers from abroad) (current LCU)",
        "Net secondary income (Net current transfers from abroad) (current US$)",
        "Net trade in goods (BoP, current US$)",
        "Net trade in goods and services (BoP, current US$)",
        "New business density (new registrations per 1,000 people ages 15-64)",
        "New businesses registered (number)",
        "Newborns protected against tetanus (%)",
        "Nitrous oxide emissions (% change from 1990)",
        "Nitrous oxide emissions (thousand metric tons of CO2 equivalent)",
        "Nitrous oxide emissions in energy sector (% of total)",
        "Nitrous oxide emissions in energy sector (thousand metric tons of CO2 equivalent)",
        "Number of deaths ages 10-14 years",
        "Number of deaths ages 15-19 years",
        "Number of deaths ages 20-24 years",
        "Number of deaths ages 5-9 years",
        "Number of infant deaths",
        "Number of maternal deaths",
        "Number of neonatal deaths",
        "Number of surgical procedures (per 100,000 population)",
        "Number of under-five deaths",
        "Number of visits or required meetings with tax officials (average for affected firms)",
        "Nurses and midwives (per 1,000 people)",
        "Official exchange rate (LCU per US$, period average)",
        "Oil rents (% of GDP)",
        "Ores and metals exports (% of merchandise exports)",
        "Ores and metals imports (% of merchandise imports)",
        "Other expense (% of expense)",
        "Other expense (current LCU)",
        "Other greenhouse gas emissions (% change from 1990)",
        "Other greenhouse gas emissions, HFC, PFC and SF6 (thousand metric tons of CO2 equivalent)",
        "Other manufacturing (% of value added in manufacturing)",
        "Other taxes (% of revenue)",
        "Other taxes (current LCU)",
        "Other taxes payable by businesses (% of commercial profits)",
        "Out-of-pocket expenditure (% of current health expenditure)",
        "Out-of-pocket expenditure per capita (current US$)",
        "Out-of-pocket expenditure per capita, PPP (current international $)",
        "Over-age students, primary (% of enrollment)",
        "Over-age students, primary, female (% of female enrollment)",
        "Over-age students, primary, male (% of male enrollment)",
        "Part time employment, female (% of total female employment)",
        "Part time employment, male (% of total male employment)",
        "Part time employment, total (% of total employment)",
        "Patent applications, nonresidents",
        "Patent applications, residents",
        "People practicing open defecation (% of population)",
        "People practicing open defecation, rural (% of rural population)",
        "People practicing open defecation, urban (% of urban population)",
        "People using at least basic drinking water services (% of population)",
        "People using at least basic drinking water services, rural (% of rural population)",
        "People using at least basic drinking water services, urban (% of urban population)",
        "People using at least basic sanitation services (% of population)",
        "People using at least basic sanitation services, rural (% of rural population)",
        "People using at least basic sanitation services, urban (% of urban population)",
        "People using safely managed drinking water services (% of population)",
        "People using safely managed drinking water services, rural (% of rural population)",
        "People using safely managed drinking water services, urban (% of urban population)",
        "People using safely managed sanitation services (% of population)",
        "People using safely managed sanitation services, rural (% of rural population)",
        "People using safely managed sanitation services, urban (% of urban population)",
        "People with basic handwashing facilities including soap and water (% of population)",
        "People with basic handwashing facilities including soap and water, rural (% of rural population)",
        "People with basic handwashing facilities including soap and water, urban (% of urban population)",
        "Periodicity and timeliness assessment of statistical capacity (scale 0 - 100)",
        "Permanent cropland (% of land area)",
        "Persistence to grade 5, female (% of cohort)",
        "Persistence to grade 5, male (% of cohort)",
        "Persistence to grade 5, total (% of cohort)",
        "Persistence to last grade of primary, female (% of cohort)",
        "Persistence to last grade of primary, male (% of cohort)",
        "Persistence to last grade of primary, total (% of cohort)",
        "Personal remittances, paid (current US$)",
        "Personal remittances, received (% of GDP)",
        "Personal remittances, received (current US$)",
        "Personal transfers, receipts (BoP, current US$)",
        "PFC gas emissions (thousand metric tons of CO2 equivalent)",
        "Physicians (per 1,000 people)",
        "Plant species (higher), threatened",
        "PM2.5 air pollution, mean annual exposure (micrograms per cubic meter)",
        "PM2.5 air pollution, population exposed to levels exceeding WHO guideline value (% of total)",
        "PM2.5 pollution, population exposed to levels exceeding WHO Interim Target-1 value (% of total)",
        "PM2.5 pollution, population exposed to levels exceeding WHO Interim Target-2 value (% of total)",
        "PM2.5 pollution, population exposed to levels exceeding WHO Interim Target-3 value (% of total)",
        "PNG, bonds (NFL, current US$)",
        "PNG, commercial banks and other creditors (NFL, current US$)",
        "Political Stability and Absence of Violence/Terrorism: Estimate",
        "Political Stability and Absence of Violence/Terrorism: Number of Sources",
        "Political Stability and Absence of Violence/Terrorism: Percentile Rank",
        "Political Stability and Absence of Violence/Terrorism: Percentile Rank, Lower Bound of 90% Confidence Interval",
        "Political Stability and Absence of Violence/Terrorism: Percentile Rank, Upper Bound of 90% Confidence Interval",
        "Political Stability and Absence of Violence/Terrorism: Standard Error",
        "Population ages 00-04, female (% of female population)",
        "Population ages 00-04, male (% of male population)",
        "Population ages 0-14 (% of total population)",
        "Population ages 0-14, female",
        "Population ages 0-14, female (% of female population)",
        "Population ages 0-14, male",
        "Population ages 0-14, male (% of male population)",
        "Population ages 0-14, total",
        "Population ages 05-09, female (% of female population)",
        "Population ages 05-09, male (% of male population)",
        "Population ages 10-14, female (% of female population)",
        "Population ages 10-14, male (% of male population)",
        "Population ages 15-19, female (% of female population)",
        "Population ages 15-19, male (% of male population)",
        "Population ages 15-64 (% of total population)",
        "Population ages 15-64, female",
        "Population ages 15-64, female (% of female population)",
        "Population ages 15-64, male",
        "Population ages 15-64, male (% of male population)",
        "Population ages 15-64, total",
        "Population ages 20-24, female (% of female population)",
        "Population ages 20-24, male (% of male population)",
        "Population ages 25-29, female (% of female population)",
        "Population ages 25-29, male (% of male population)",
        "Population ages 30-34, female (% of female population)",
        "Population ages 30-34, male (% of male population)",
        "Population ages 35-39, female (% of female population)",
        "Population ages 35-39, male (% of male population)",
        "Population ages 40-44, female (% of female population)",
        "Population ages 40-44, male (% of male population)",
        "Population ages 45-49, female (% of female population)",
        "Population ages 45-49, male (% of male population)",
        "Population ages 50-54, female (% of female population)",
        "Population ages 50-54, male (% of male population)",
        "Population ages 55-59, female (% of female population)",
        "Population ages 55-59, male (% of male population)",
        "Population ages 60-64, female (% of female population)",
        "Population ages 60-64, male (% of male population)",
        "Population ages 65 and above (% of total population)",
        "Population ages 65 and above, female",
        "Population ages 65 and above, female (% of female population)",
        "Population ages 65 and above, male",
        "Population ages 65 and above, male (% of male population)",
        "Population ages 65 and above, total",
        "Population ages 65-69, female (% of female population)",
        "Population ages 65-69, male (% of male population)",
        "Population ages 70-74, female (% of female population)",
        "Population ages 70-74, male (% of male population)",
        "Population ages 75-79, female (% of female population)",
        "Population ages 75-79, male (% of male population)",
        "Population ages 80 and above, female (% of female population)",
        "Population ages 80 and above, male (% of male population)",
        "Population density (people per sq. km of land area)",
        "Population growth (annual %)",
        "Population in largest city",
        "Population in the largest city (% of urban population)",
        "Population in urban agglomerations of more than 1 million",
        "Population in urban agglomerations of more than 1 million (% of total population)",
        "Population living in areas where elevation is below 5 meters (% of total population)",
        "Population living in slums (% of urban population)",
        "Population, female",
        "Population, female (% of total population)",
        "Population, male",
        "Population, male (% of total population)",
        "Population, total",
        "Portfolio equity, net inflows (BoP, current US$)",
        "Portfolio investment, bonds (PPG + PNG) (NFL, current US$)",
        "Portfolio investment, net (BoP, current US$)",
        "Poverty gap at $2.15 a day (2017 PPP) (%)",
        "Poverty gap at $3.65 a day (2017 PPP) (%)",
        "Poverty gap at $6.85 a day (2017 PPP) (%)",
        "Poverty headcount ratio at $2.15 a day (2017 PPP) (% of population)",
        "Poverty headcount ratio at $3.65 a day (2017 PPP) (% of population)",
        "Poverty headcount ratio at $6.85 a day (2017 PPP) (% of population)",
        "Poverty headcount ratio at national poverty lines (% of population)",
        "Poverty headcount ratio at societal poverty line (% of population)",
        "Power outages in firms in a typical month (number)",
        "PPG, bonds (NFL, current US$)",
        "PPG, commercial banks (NFL, current US$)",
        "PPG, IBRD (DOD, current US$)",
        "PPG, IDA (DOD, current US$)",
        "PPG, official creditors (NFL, US$)",
        "PPG, other private creditors (NFL, current US$)",
        "PPG, private creditors (NFL, US$)",
        "PPP conversion factor, GDP (LCU per international $)",
        "PPP conversion factor, private consumption (LCU per international $)",
        "Pregnant women receiving prenatal care (%)",
        "Preprimary education, duration (years)",
        "Present value of external debt (% of exports of goods, services and primary income)",
        "Present value of external debt (% of GNI)",
        "Present value of external debt (current US$)",
        "Prevalence of anemia among children (% of children ages 6-59 months)",
        "Prevalence of anemia among non-pregnant women (% of women ages 15-49)",
        "Prevalence of anemia among pregnant women (%)",
        "Prevalence of anemia among women of reproductive age (% of women ages 15-49)",
        "Prevalence of current tobacco use (% of adults)",
        "Prevalence of current tobacco use, females (% of female adults)",
        "Prevalence of current tobacco use, males (% of male adults)",
        "Prevalence of HIV, female (% ages 15-24)",
        "Prevalence of HIV, male (% ages 15-24)",
        "Prevalence of HIV, total (% of population ages 15-49)",
        "Prevalence of moderate or severe food insecurity in the population (%)",
        "Prevalence of overweight (modeled estimate, % of children under 5)",
        "Prevalence of overweight, weight for height (% of children under 5)",
        "Prevalence of overweight, weight for height, female (% of children under 5)",
        "Prevalence of overweight, weight for height, male (% of children under 5)",
        "Prevalence of severe food insecurity in the population (%)",
        "Prevalence of severe wasting, weight for height (% of children under 5)",
        "Prevalence of severe wasting, weight for height, female (% of children under 5)",
        "Prevalence of severe wasting, weight for height, male (% of children under 5)",
        "Prevalence of stunting, height for age (% of children under 5)",
        "Prevalence of stunting, height for age (modeled estimate, % of children under 5)",
        "Prevalence of stunting, height for age, female (% of children under 5)",
        "Prevalence of stunting, height for age, male (% of children under 5)",
        "Prevalence of undernourishment (% of population)",
        "Prevalence of underweight, weight for age (% of children under 5)",
        "Prevalence of underweight, weight for age, female (% of children under 5)",
        "Prevalence of underweight, weight for age, male (% of children under 5)",
        "Prevalence of wasting, weight for height (% of children under 5)",
        "Prevalence of wasting, weight for height, female (% of children under 5)",
        "Prevalence of wasting, weight for height, male (% of children under 5)",
        "Price level ratio of PPP conversion factor (GDP) to market exchange rate",
        "Primary completion rate, female (% of relevant age group)",
        "Primary completion rate, male (% of relevant age group)",
        "Primary completion rate, total (% of relevant age group)",
        "Primary education, duration (years)",
        "Primary education, pupils",
        "Primary education, pupils (% female)",
        "Primary education, teachers",
        "Primary education, teachers (% female)",
        "Primary government expenditures as a proportion of original approved budget (%)",
        "Primary income payments (BoP, current US$)",
        "Primary income receipts (BoP, current US$)",
        "Primary school age children out-of-school (%)",
        "Primary school starting age (years)",
        "Private credit bureau coverage (% of adults)",
        "Probability of dying among adolescents ages 10-14 years (per 1,000)",
        "Probability of dying among adolescents ages 15-19 years (per 1,000)",
        "Probability of dying among children ages 5-9 years (per 1,000)",
        "Probability of dying among youth ages 20-24 years (per 1,000)",
        "Procedures to build a warehouse (number)",
        "Procedures to register property (number)",
        "Profit tax (% of commercial profits)",
        "Progression to secondary school (%)",
        "Progression to secondary school, female (%)",
        "Progression to secondary school, male (%)",
        "Proportion of people living below 50 percent of median income (%)",
        "Proportion of population pushed below the $2.15 ($ 2017 PPP) poverty line by out-of-pocket health care expenditure (%)",
        "Proportion of population pushed below the $3.65 ($ 2017 PPP) poverty line by out-of-pocket health care expenditure (%)",
        "Proportion of population pushed below the 60% median consumption poverty line by out-of-pocket health expenditure (%)",
        "Proportion of population pushed further below the $2.15 ($ 2017 PPP) poverty line by out-of-pocket health care expenditure (%)",
        "Proportion of population pushed further below the $3.65 ($ 2017 PPP) poverty line by out-of-pocket health care expenditure (%)",
        "Proportion of population pushed further below the 60% median consumption poverty line by out-of-pocket health care expenditure (%)",
        "Proportion of population pushed or further pushed below the $2.15 ($ 2017 PPP) poverty line by out-of-pocket health care expenditure (%)",
        "Proportion of population pushed or further pushed below the $3.65 ($ 2017 PPP) poverty line by out-of-pocket health care expenditure (%)",
        "Proportion of population pushed or further pushed below the 60% median consumption poverty line by out-of-pocket health expenditure (%)",
        "Proportion of population spending more than 10% of household consumption or income on out-of-pocket health care expenditure (%)",
        "Proportion of population spending more than 25% of household consumption or income on out-of-pocket health care expenditure (%)",
        "Proportion of seats held by women in national parliaments (%)",
        "Proportion of time spent on unpaid domestic and care work, female (% of 24 hour day)",
        "Proportion of time spent on unpaid domestic and care work, male (% of 24 hour day)",
        "Proportion of women subjected to physical and/or sexual violence in the last 12 months (% of ever-partnered women ages 15-49)",
        "Public and publicly guaranteed debt service (% of exports of goods, services and primary income)",
        "Public and publicly guaranteed debt service (% of GNI)",
        "Public credit registry coverage (% of adults)",
        "Public private partnerships investment in energy (current US$)",
        "Public private partnerships investment in ICT (current US$)",
        "Public private partnerships investment in transport (current US$)",
        "Public private partnerships investment in water and sanitation (current US$)",
        "Pump price for diesel fuel (US$ per liter)",
        "Pump price for gasoline (US$ per liter)",
        "Pupils below minimum reading proficiency at end of primary (%). Low GAML threshold",
        "Pupil-teacher ratio, lower secondary",
        "Pupil-teacher ratio, preprimary",
        "Pupil-teacher ratio, primary",
        "Pupil-teacher ratio, secondary",
        "Pupil-teacher ratio, tertiary",
        "Pupil-teacher ratio, upper secondary",
        "Rail lines (total route-km)",
        "Railways, goods transported (million ton-km)",
        "Railways, passengers carried (million passenger-km)",
        "Ratio of female to male labor force participation rate (%) (modeled ILO estimate)",
        "Ratio of female to male labor force participation rate (%) (national estimate)",
        "Real effective exchange rate index (2010 = 100)",
        "Real interest rate (%)",
        "Refugee population by country or territory of asylum",
        "Refugee population by country or territory of origin",
        "Regulatory Quality: Estimate",
        "Regulatory Quality: Number of Sources",
        "Regulatory Quality: Percentile Rank",
        "Regulatory Quality: Percentile Rank, Lower Bound of 90% Confidence Interval",
        "Regulatory Quality: Percentile Rank, Upper Bound of 90% Confidence Interval",
        "Regulatory Quality: Standard Error",
        "Renewable electricity output (% of total electricity output)",
        "Renewable energy consumption (% of total final energy consumption)",
        "Renewable internal freshwater resources per capita (cubic meters)",
        "Renewable internal freshwater resources, total (billion cubic meters)",
        "Repeaters, primary, female (% of female enrollment)",
        "Repeaters, primary, male (% of male enrollment)",
        "Repeaters, primary, total (% of total enrollment)",
        "Research and development expenditure (% of GDP)",
        "Researchers in R&D (per million people)",
        "Reserves and related items (BoP, current US$)",
        "Revenue, excluding grants (% of GDP)",
        "Revenue, excluding grants (current LCU)",
        "Risk of catastrophic expenditure for surgical care (% of people at risk)",
        "Risk of impoverishing expenditure for surgical care (% of people at risk)",
        "Risk premium on lending (lending rate minus treasury bill rate, %)",
        "Rule of Law: Estimate",
        "Rule of Law: Number of Sources",
        "Rule of Law: Percentile Rank",
        "Rule of Law: Percentile Rank, Lower Bound of 90% Confidence Interval",
        "Rule of Law: Percentile Rank, Upper Bound of 90% Confidence Interval",
        "Rule of Law: Standard Error",
        "Rural land area (sq. km)",
        "Rural land area where elevation is below 5 meters (% of total land area)",
        "Rural land area where elevation is below 5 meters (sq. km)",
        "Rural population",
        "Rural population (% of total population)",
        "Rural population growth (annual %)",
        "Rural population living in areas where elevation is below 5 meters (% of total population)",
        "S&P Global Equity Indices (annual % change)",
        "School enrollment, preprimary (% gross)",
        "School enrollment, preprimary, female (% gross)",
        "School enrollment, preprimary, male (% gross)",
        "School enrollment, primary (% gross)",
        "School enrollment, primary (% net)",
        "School enrollment, primary (gross), gender parity index (GPI)",
        "School enrollment, primary and secondary (gross), gender parity index (GPI)",
        "School enrollment, primary, female (% gross)",
        "School enrollment, primary, female (% net)",
        "School enrollment, primary, male (% gross)",
        "School enrollment, primary, male (% net)",
        "School enrollment, primary, private (% of total primary)",
        "School enrollment, secondary (% gross)",
        "School enrollment, secondary (% net)",
        "School enrollment, secondary (gross), gender parity index (GPI)",
        "School enrollment, secondary, female (% gross)",
        "School enrollment, secondary, female (% net)",
        "School enrollment, secondary, male (% gross)",
        "School enrollment, secondary, male (% net)",
        "School enrollment, secondary, private (% of total secondary)",
        "School enrollment, tertiary (% gross)",
        "School enrollment, tertiary (gross), gender parity index (GPI)",
        "School enrollment, tertiary, female (% gross)",
        "School enrollment, tertiary, male (% gross)",
        "Scientific and technical journal articles",
        "Secondary education, duration (years)",
        "Secondary education, general pupils",
        "Secondary education, general pupils (% female)",
        "Secondary education, pupils",
        "Secondary education, pupils (% female)",
        "Secondary education, teachers",
        "Secondary education, teachers (% female)",
        "Secondary education, teachers, female",
        "Secondary education, vocational pupils",
        "Secondary education, vocational pupils (% female)",
        "Secondary income receipts (BoP, current US$)",
        "Secondary income, other sectors, payments (BoP, current US$)",
        "Secure Internet servers",
        "Secure Internet servers (per 1 million people)",
        "Self-employed, female (% of female employment) (modeled ILO estimate)",
        "Self-employed, male (% of male employment) (modeled ILO estimate)",
        "Self-employed, total (% of total employment) (modeled ILO estimate)",
        "Service exports (BoP, current US$)",
        "Service imports (BoP, current US$)",
        "Services, value added (% of GDP)",
        "Services, value added (annual % growth)",
        "Services, value added (constant 2015 US$)",
        "Services, value added (constant LCU)",
        "Services, value added (current LCU)",
        "Services, value added (current US$)",
        "Services, value added per worker (constant 2015 US$)",
        "Sex ratio at birth (male births per female births)",
        "SF6 gas emissions (thousand metric tons of CO2 equivalent)",
        "Share of tariff lines with international peaks, all products (%)",
        "Share of tariff lines with international peaks, manufactured products (%)",
        "Share of tariff lines with international peaks, primary products (%)",
        "Share of tariff lines with specific rates, all products (%)",
        "Share of tariff lines with specific rates, manufactured products (%)",
        "Share of tariff lines with specific rates, primary products (%)",
        "Share of youth not in education, employment or training, female (% of female youth population)",
        "Share of youth not in education, employment or training, male (% of male youth population)",
        "Share of youth not in education, employment or training, total (% of youth population)",
        "Short-term debt (% of exports of goods, services and primary income)",
        "Short-term debt (% of total external debt)",
        "Short-term debt (% of total reserves)",
        "Social contributions (% of revenue)",
        "Social contributions (current LCU)",
        "Source data assessment of statistical capacity (scale 0 - 100)",
        "Specialist surgical workforce (per 100,000 population)",
        "Start-up procedures to register a business (number)",
        "Start-up procedures to register a business, female (number)",
        "Start-up procedures to register a business, male (number)",
        "Statistical Capacity Score (Overall Average) (scale 0 - 100)",
        "Statistical performance indicators (SPI): Overall score (scale 0-100)",
        "Statistical performance indicators (SPI): Pillar 1 data use score (scale 0-100)",
        "Statistical performance indicators (SPI): Pillar 2 data services score (scale 0-100)",
        "Statistical performance indicators (SPI): Pillar 3 data products score (scale 0-100)",
        "Statistical performance indicators (SPI): Pillar 4 data sources score (scale 0-100)",
        "Statistical performance indicators (SPI): Pillar 5 data infrastructure score (scale 0-100)",
        "Stocks traded, total value (% of GDP)",
        "Stocks traded, total value (current US$)",
        "Stocks traded, turnover ratio of domestic shares (%)",
        "Strength of legal rights index (0=weak to 12=strong)",
        "Subsidies and other transfers (% of expense)",
        "Subsidies and other transfers (current LCU)",
        "Suicide mortality rate (per 100,000 population)",
        "Suicide mortality rate (per 100,000 population)",
        "Suicide mortality rate, female (per 100,000 female population)",
        "Suicide mortality rate, male (per 100,000 male population)",
        "Surface area (sq. km)",
        "Survey mean consumption or income per capita, bottom 40% of population (2017 PPP $ per day)",
        "Survey mean consumption or income per capita, total population (2017 PPP $ per day)",
        "Survival to age 65, female (% of cohort)",
        "Survival to age 65, male (% of cohort)",
        "Tariff rate, applied, simple mean, all products (%)",
        "Tariff rate, applied, simple mean, manufactured products (%)",
        "Tariff rate, applied, simple mean, primary products (%)",
        "Tariff rate, applied, weighted mean, all products (%)",
        "Tariff rate, applied, weighted mean, manufactured products (%)",
        "Tariff rate, applied, weighted mean, primary products (%)",
        "Tariff rate, most favored nation, simple mean, all products (%)",
        "Tariff rate, most favored nation, simple mean, manufactured products (%)",
        "Tariff rate, most favored nation, simple mean, primary products (%)",
        "Tariff rate, most favored nation, weighted mean, all products (%)",
        "Tariff rate, most favored nation, weighted mean, manufactured products (%)",
        "Tariff rate, most favored nation, weighted mean, primary products (%)",
        "Tax payments (number)",
        "Tax revenue (% of GDP)",
        "Tax revenue (current LCU)",
        "Taxes less subsidies on products (constant LCU)",
        "Taxes less subsidies on products (current LCU)",
        "Taxes less subsidies on products (current US$)",
        "Taxes on exports (% of tax revenue)",
        "Taxes on exports (current LCU)",
        "Taxes on goods and services (% of revenue)",
        "Taxes on goods and services (% value added of industry and services)",
        "Taxes on goods and services (current LCU)",
        "Taxes on income, profits and capital gains (% of revenue)",
        "Taxes on income, profits and capital gains (% of total taxes)",
        "Taxes on income, profits and capital gains (current LCU)",
        "Taxes on international trade (% of revenue)",
        "Taxes on international trade (current LCU)",
        "Technical cooperation grants (BoP, current US$)",
        "Technicians in R&D (per million people)",
        "Teenage mothers (% of women ages 15-19 who have had children or are currently pregnant)",
        "Terms of trade adjustment (constant LCU)",
        "Terrestrial and marine protected areas (% of total territorial area)",
        "Terrestrial protected areas (% of total land area)",
        "Tertiary education, academic staff (% female)",
        "Textiles and clothing (% of value added in manufacturing)",
        "Time required to build a warehouse (days)",
        "Time required to enforce a contract (days)",
        "Time required to get electricity (days)",
        "Time required to obtain an operating license (days)",
        "Time required to register property (days)",
        "Time required to start a business (days)",
        "Time required to start a business, female (days)",
        "Time required to start a business, male (days)",
        "Time spent dealing with the requirements of government regulations (% of senior management time)",
        "Time to export, border compliance (hours)",
        "Time to export, documentary compliance (hours)",
        "Time to import, border compliance (hours)",
        "Time to import, documentary compliance (hours)",
        "Time to obtain an electrical connection (days)",
        "Time to prepare and pay taxes (hours)",
        "Time to resolve insolvency (years)",
        "Total alcohol consumption per capita (liters of pure alcohol, projected estimates, 15+ years of age)",
        "Total alcohol consumption per capita, female (liters of pure alcohol, projected estimates, female 15+ years of age)",
        "Total alcohol consumption per capita, male (liters of pure alcohol, projected estimates, male 15+ years of age)",
        "Total debt service (% of exports of goods, services and primary income)",
        "Total debt service (% of GNI)",
        "Total fisheries production (metric tons)",
        "Total greenhouse gas emissions (% change from 1990)",
        "Total greenhouse gas emissions (kt of CO2 equivalent)",
        "Total natural resources rents (% of GDP)",
        "Total reserves (% of total external debt)",
        "Total reserves (includes gold, current US$)",
        "Total reserves in months of imports",
        "Total reserves minus gold (current US$)",
        "Total tax and contribution rate (% of profit)",
        "Trade (% of GDP)",
        "Trade in services (% of GDP)",
        "Trademark applications, nonresident, by count",
        "Trademark applications, resident, by count",
        "Trained teachers in lower secondary education (% of total teachers)",
        "Trained teachers in lower secondary education, female (% of female teachers)",
        "Trained teachers in lower secondary education, male (% of male teachers)",
        "Trained teachers in preprimary education (% of total teachers)",
        "Trained teachers in preprimary education, female (% of female teachers)",
        "Trained teachers in preprimary education, male (% of male teachers)",
        "Trained teachers in primary education (% of total teachers)",
        "Trained teachers in primary education, female (% of female teachers)",
        "Trained teachers in primary education, male (% of male teachers)",
        "Trained teachers in secondary education (% of total teachers)",
        "Trained teachers in secondary education, female (% of female teachers)",
        "Trained teachers in secondary education, male (% of male teachers)",
        "Trained teachers in upper secondary education (% of total teachers)",
        "Trained teachers in upper secondary education, female (% of female teachers)",
        "Trained teachers in upper secondary education, male (% of male teachers)",
        "Transport services (% of commercial service exports)",
        "Transport services (% of commercial service imports)",
        "Transport services (% of service exports, BoP)",
        "Transport services (% of service imports, BoP)",
        "Travel services (% of commercial service exports)",
        "Travel services (% of commercial service imports)",
        "Travel services (% of service exports, BoP)",
        "Travel services (% of service imports, BoP)",
        "Tuberculosis case detection rate (%, all forms)",
        "Tuberculosis treatment success rate (% of new cases)",
        "UHC service coverage index",
        "Unemployment with advanced education (% of total labor force with advanced education)",
        "Unemployment with advanced education, female (% of female labor force with advanced education)",
        "Unemployment with advanced education, male (% of male labor force with advanced education)",
        "Unemployment with basic education (% of total labor force with basic education)",
        "Unemployment with basic education, female (% of female labor force with basic education)",
        "Unemployment with basic education, male (% of male labor force with basic education)",
        "Unemployment with intermediate education (% of total labor force with intermediate education)",
        "Unemployment with intermediate education, female (% of female labor force with intermediate education)",
        "Unemployment with intermediate education, male (% of male labor force with intermediate education)",
        "Unemployment, female (% of female labor force) (modeled ILO estimate)",
        "Unemployment, female (% of female labor force) (national estimate)",
        "Unemployment, male (% of male labor force) (modeled ILO estimate)",
        "Unemployment, male (% of male labor force) (national estimate)",
        "Unemployment, total (% of total labor force) (modeled ILO estimate)",
        "Unemployment, total (% of total labor force) (national estimate)",
        "Unemployment, youth female (% of female labor force ages 15-24) (modeled ILO estimate)",
        "Unemployment, youth female (% of female labor force ages 15-24) (national estimate)",
        "Unemployment, youth male (% of male labor force ages 15-24) (modeled ILO estimate)",
        "Unemployment, youth male (% of male labor force ages 15-24) (national estimate)",
        "Unemployment, youth total (% of total labor force ages 15-24) (modeled ILO estimate)",
        "Unemployment, youth total (% of total labor force ages 15-24) (national estimate)",
        "Unmet need for contraception (% of married women ages 15-49)",
        "Urban land area (sq. km)",
        "Urban land area where elevation is below 5 meters (% of total land area)",
        "Urban land area where elevation is below 5 meters (sq. km)",
        "Urban population",
        "Urban population (% of total population)",
        "Urban population growth (annual %)",
        "Urban population living in areas where elevation is below 5 meters (% of total population)",
        "Use of IMF credit (DOD, current US$)",
        "Use of insecticide-treated bed nets (% of under-5 population)",
        "Value lost due to electrical outages (% of sales for affected firms)",
        "Vitamin A supplementation coverage rate (% of children ages 6-59 months)",
        "Voice and Accountability: Estimate",
        "Voice and Accountability: Number of Sources",
        "Voice and Accountability: Percentile Rank",
        "Voice and Accountability: Percentile Rank, Lower Bound of 90% Confidence Interval",
        "Voice and Accountability: Percentile Rank, Upper Bound of 90% Confidence Interval",
        "Voice and Accountability: Standard Error",
        "Vulnerable employment, female (% of female employment) (modeled ILO estimate)",
        "Vulnerable employment, male (% of male employment) (modeled ILO estimate)",
        "Vulnerable employment, total (% of total employment) (modeled ILO estimate)",
        "Wage and salaried workers, female (% of female employment) (modeled ILO estimate)",
        "Wage and salaried workers, male (% of male employment) (modeled ILO estimate)",
        "Wage and salaried workers, total (% of total employment) (modeled ILO estimate)",
        "Wanted fertility rate (births per woman)",
        "Water productivity, total (constant 2015 US$ GDP per cubic meter of total freshwater withdrawal)",
        "Wholesale price index (2010 = 100)",
        "Women Business and the Law Index Score (scale 1-100)",
        "Women making their own informed decisions regarding sexual relations, contraceptive use and reproductive health care (% of women age 15-49)",
        "Women participating in the three decisions (own health care, major household purchases, and visiting family) (% of women age 15-49)",
        "Women who believe a husband is justified in beating his wife (any of five reasons) (%)",
        "Women who believe a husband is justified in beating his wife when she argues with him (%)",
        "Women who believe a husband is justified in beating his wife when she burns the food (%)",
        "Women who believe a husband is justified in beating his wife when she goes out without telling him (%)",
        "Women who believe a husband is justified in beating his wife when she neglects the children (%)",
        "Women who believe a husband is justified in beating his wife when she refuses sex with him (%)",
        "Women who were first married by age 15 (% of women ages 20-24)",
        "Women who were first married by age 18 (% of women ages 20-24)",
        "Women's share of population ages 15+ living with HIV (%)",
        "Young people (ages 15-24) newly infected with HIV"
    ],
    "OECD": [
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Household indicators",
        "Wealth distribution",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Households' financial assets and liabilities",
        "Institutional investors' indicators",
        "Institutional investors' assets and liabilities",
        "Aggregate National Accounts, SNA 1993: Disposable income and net lending/borrowing",
        "Aggregate National Accounts, SNA 1993: Gross domestic product",
        "Aggregate National Accounts, SNA 1993: Population and employment by main activity",
        "Detailed National Accounts, SNA 1993: Capital formation by activity - ISIC Rev. 3",
        "Detailed National Accounts, SNA 1993: Capital formation by activity - ISIC Rev. 4",
        "Detailed National Accounts, SNA 1993: Final consumption expenditure of households",
        "Detailed National Accounts, SNA 1993: Fixed assets by activity and by type of product - ISIC Rev. 3",
        "Detailed National Accounts, SNA 1993: Non-financial accounts by sectors, annual",
        "Detailed National Accounts, SNA 1993: Simplified non-financial accounts",
        "Detailed National Accounts, SNA 1993: Value added and its components by activity - ISIC Rev. 3",
        "Detailed National Accounts, SNA 1993: Value added and its components by activity - ISIC Rev. 4",
        "Financial Accounts, SNA 2008 (or SNA 1993): Consolidated flows, quarterly",
        "Financial Accounts, SNA 2008 (or SNA 1993): Non-consolidated flows, quarterly",
        "Financial Balance Sheets, SNA 2008 (or SNA 1993): Consolidated stocks, quarterly",
        "Financial Balance Sheets, SNA 2008 (or SNA 1993): Non-consolidated stocks, quarterly",
        "General Government Accounts, SNA 1993: Government expenditure by function",
        "General Government Accounts, SNA 1993: Main aggregates",
        "General Government Accounts, SNA 1993: Taxes and social contributions receipts",
        "Personal Income Tax: Marginal rates and social security contributions",
        "Balance of payments: Balance of payments BPM5",
        "Business tendency and consumer opinion surveys",
        "Composite leading indicators",
        "Finance",
        "International trade",
        "Key short-term indicators",
        "Labour: Labour market statistics",
        "Labour: Unit labour cost - quarterly indicators - early estimates",
        "Main Economic Indicators - complete database",
        "Prices: Consumer prices for G20",
        "Prices: Consumer prices, archive (Edition 2018)",
        "Prices: Consumer prices: national weights",
        "Prices: Country weights in area totals",
        "Prices: Producer prices",
        "Prices: Purchasing Power Parities for GDP and related indicators",
        "Production and sales",
        "Better Life Index",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: financial flows by industry",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: financial flows by partner country",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: financial flows, main aggregates",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: income by industry",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: income by partner country",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: positions by industry",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: positions by partner country",
        "Benchmark definition, 4th edition (BMD4): Foreign direct investment: positions, main aggregates",
        "Green growth indicators",
        "Waste: Municipal waste",
        "Forest resources",
        "Biodiversity: Threatened species",
        "Air and climate: Air and greenhouse gas emissions by industry",
        "Balance sheet and income",
        "Commissions",
        "Gross claims payments",
        "Gross operating expenses",
        "Insurance activity indicators",
        "Insurance business by domestic and foreign risks",
        "Insurance business written abroad by branches",
        "Insurance business written in the reporting country",
        "Insurance companies and employees",
        "Outstanding investment by direct insurance companies",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Capital formation by activity - ISIC Rev. 4",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Labour input by activity - ISIC Rev. 4",
        "Gender Equality: Gender equality in education",
        "Gender Equality: Gender equality in entrepreneurship",
        "Revenue Statistics: Austria",
        "Revenue Statistics: Belgium",
        "Revenue Statistics: Canada",
        "Revenue Statistics: Chile",
        "Revenue Statistics: Comparative tables",
        "Revenue Statistics: Czech Republic",
        "Revenue Statistics: Denmark",
        "Revenue Statistics: Estonia",
        "Revenue Statistics: Finland",
        "Revenue Statistics: France",
        "Revenue Statistics: Germany",
        "Revenue Statistics: Greece",
        "Revenue Statistics: Hungary",
        "Revenue Statistics: Iceland",
        "Revenue Statistics: Ireland",
        "Revenue Statistics: Israel",
        "Revenue Statistics: Italy",
        "Revenue Statistics: Japan",
        "Revenue Statistics: Korea",
        "Revenue Statistics: Luxembourg",
        "Revenue Statistics: Mexico",
        "Revenue Statistics: Netherlands",
        "Revenue Statistics: New Zealand",
        "Revenue Statistics: Norway",
        "Revenue Statistics: Poland",
        "Revenue Statistics: Portugal",
        "Revenue Statistics: Slovak Republic",
        "Revenue Statistics: Slovenia",
        "Revenue Statistics: Spain",
        "Revenue Statistics: Sweden",
        "Revenue Statistics: Switzerland",
        "Revenue Statistics: Turkey",
        "Revenue Statistics: United Kingdom",
        "Revenue Statistics: United States",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Value added and its components by activity - ISIC Rev. 4",
        "Financial Balance Sheets, SNA 2008 (or SNA 1993): Non-consolidated stocks, annual",
        "Financial Accounts, SNA 2008 (or SNA 1993): Consolidated flows, annual",
        "Financial Accounts, SNA 2008 (or SNA 1993): Non-consolidated flows, annual",
        "Financial Balance Sheets, SNA 2008 (or SNA 1993): Consolidated stocks, annual",
        "Fisheries: Employment in fisheries",
        "Fisheries: Fishing fleet",
        "Fisheries: Foreign landings in domestic ports",
        "Fisheries: National landings in domestic ports",
        "Fisheries: Recreational fisheries",
        "Fisheries: Trade by product",
        "Labour: Registered unemployed and job vacancies",
        "Regional well-being",
        "Education at a glance: ISCED-97, Access to education, participation and progression",
        "Education at a glance: ISCED-97, Education and learning outputs and outcomes",
        "Education at a glance: ISCED-97, Financial and human resources investment in education",
        "Quarterly National Accounts",
        "Public Sector Debt: Public sector debt - consolidated",
        "Hours Worked: Average annual hours actually worked",
        "Hours Worked: Average usual weekly hours worked - averages",
        "Hours Worked: Usual hours worked by weekly hour bands",
        "Hours Worked: Usual weekly hours worked - incidence",
        "Labour Market Statistics: Discouraged workers",
        "Labour Market Statistics: Discouraged workers - incidence",
        "Labour Market Statistics: Economic short time workers",
        "Labour Market Statistics: Economic short time workers: incidence",
        "Labour Market Statistics: Employment by job tenure intervals: average tenure",
        "Labour Market Statistics: Employment by job tenure intervals: persons",
        "Labour Market Statistics: Employment by permanency of the job",
        "Labour Market Statistics: Employment by permanency of the job: incidence",
        "Labour Market Statistics: Full-time part-time employment - common definition",
        "Labour Market Statistics: Full-time part-time employment - common definition: incidence",
        "Labour Market Statistics: Full-time part-time employment - national definitions",
        "Labour Market Statistics: Full-time part-time employment - national definitions: incidence",
        "Labour Market Statistics: Involuntary part time workers",
        "Labour Market Statistics: Labour force statistics by sex and age",
        "Labour Market Statistics: Labour force statistics by sex and age: indicators",
        "Labour Market Statistics: Labour force statistics by sex and proportion of age group",
        "Trade Unions: Trade union density",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Households' assets and liabilities",
        "Financial Accounts, SNA 1993: Consolidated flows, annual",
        "Financial Accounts, SNA 1993: Non-consolidated flows, annual",
        "Financial Balance Sheets, SNA 1993: Consolidated stocks, annual",
        "Financial Balance Sheets, SNA 1993: Non-consolidated stocks, annual",
        "Main Science and Technology Indicators",
        "General Government Accounts, SNA 2008 (or SNA 1993): Government expenditure by function",
        "General Government Accounts, SNA 2008 (or SNA 1993): Taxes and social contributions receipts",
        "Benchmark definition, 3rd edition (BMD3): Foreign direct investment: main aggregates",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Final consumption expenditure of households",
        "Labour Force Statistics: Employment by activities and status",
        "Labour Force Statistics: Population and labour force",
        "Labour Force Statistics: Population projections",
        "Labour Force Statistics: Summary tables",
        "Air and climate: Greenhouse gas emissions by source",
        "GDP per capita and productivity growth",
        "GDP per capita and productivity levels",
        "Productivity and unit labour cost by industry, ISIC Rev. 4",
        "Research and Development Statistics: Business enterprise R-D expenditure by industry and by type of cost - ISIC Rev 3",
        "Research and Development Statistics: Business enterprise R-D expenditure by size class and by source of funds - ISIC Rev 3",
        "Research and Development Statistics: Business enterprise R-D personnel by industry",
        "Research and Development Statistics: Gross domestic expenditure on R-D by sector of performance and field of science",
        "Research and Development Statistics: Gross domestic expenditure on R-D by sector of performance and socio-economic objective",
        "Research and Development Statistics: Gross Domestic Expenditure on R-D by sector of performance and type of cost",
        "Research and Development Statistics: R-D personnel by sector of employment and field of science",
        "Research and Development Statistics: R-D personnel by sector of employment and qualification",
        "Indicators of international co-operation",
        "Patents by regions",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Capital formation by activity - ISIC Rev. 3",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Balance sheets for non-financial assets",
        "Air and climate: Air emissions by source",
        "Water: Freshwater resources",
        "Water: Wastewater treatment",
        "Earnings: Real minimum wages",
        "PPPs and exchange rates",
        "General Government Accounts, SNA 2008 (or SNA 1993): Main aggregates",
        "Aggregate National Accounts, SNA 2008 (or SNA 1993): Disposable income and net lending/borrowing",
        "Aggregate National Accounts, SNA 2008 (or SNA 1993): Gross domestic product",
        "Maastricht debt",
        "Earnings: Gross earnings: decile ratios",
        "Earnings: Minimum wages relative to median wages",
        "Pensions indicators",
        "Institutional investors assets",
        "Inward activity of multinationals by industrial sector - ISIC Rev. 4",
        "Inward activity of multinationals by investing country - ISIC Rev. 4",
        "Outward activity of multinationals by country of location - ISIC Rev. 4",
        "Outward activity of multinationals by industrial sector - ISIC Rev. 4",
        "Employment Protection Legislation: Strictness of employment protection legislation: individual and collective dismissals",
        "Employment Protection Legislation: Strictness of employment protection legislation: collective dismissals",
        "Employment Protection Legislation: Strictness of employment protection legislation: regular employment",
        "Employment Protection Legislation: Strictness of employment protection legislation: temporary employment",
        "Aggregate National Accounts, SNA 2008 (or SNA 1993): Population and employment by main activity",
        "Labour Market Statistics: Unemployment by duration: average number of months",
        "Labour Market Statistics: Unemployment by duration",
        "Labour Market Statistics: Unemployment by duration: incidence",
        "Waste: Waste generation by sector",
        "Metropolitan areas",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Fixed assets by activity and by type of product - ISIC Rev. 3",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Labour input by activity - ISIC Rev. 3",
        "Social Expenditure: Reference series",
        "Financial Accounts, SNA 1993: Consolidated flows, quarterly",
        "Financial Accounts, SNA 1993: Non-consolidated flows, quarterly",
        "Financial Balance Sheets, SNA 1993: Consolidated stocks, quarterly",
        "Financial Balance Sheets, SNA 1993: Non-consolidated stocks, quarterly",
        "Financial Accounts, SNA 2008 (or SNA 1993): Financial indicators - flows",
        "Financial Balance Sheets, SNA 2008 (or SNA 1993): Financial indicators - stocks",
        "Trade by enterprise characteristics: Trade by top enterprises, ISIC Rev. 4",
        "Trade by enterprise characteristics: Trade by commodity groups, ISIC Rev. 3",
        "Trade by enterprise characteristics: Trade by number of partner countries, ISIC Rev. 3",
        "Trade by enterprise characteristics: Trade by top enterprises, ISIC Rev. 3",
        "Trade by enterprise characteristics: Trading partners, ISIC Rev. 3",
        "STAN Industry ISIC Rev. 3 (Edition 2011)",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Non-financial accounts by sectors, annual",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Simplified non-financial accounts",
        "Detailed National Accounts, SNA 2008 (or SNA 1993): Value added and its components by activity - ISIC Rev 3"
    ],
    "WHO": whoData,
    "NASA": [
        "Aggregate National Accounts, SNA 1993: Disposable income and net lending/borrowing",
        "Aggregate National Accounts, SNA 1993: Gross domestic product",
    ],
    "FED": [
        "Aggregate National Accounts, SNA 1993: Population and employment by main activity",
        "Detailed National Accounts, SNA 1993: Capital formation by activity - ISIC Rev. 3",
    ],
    "US GOV": [
        "Detailed National Accounts, SNA 1993: Capital formation by activity - ISIC Rev. 4",
        "Detailed National Accounts, SNA 1993: Final consumption expenditure of households",
    ],
    "ILO": [
        "Detailed National Accounts, SNA 1993: Fixed assets by activity and by type of product - ISIC Rev. 3",
        "Detailed National Accounts, SNA 1993: Non-financial accounts by sectors, annual",
    ],
    "UNESCO": [],
    "KAGGLE": [],
    "JAMES WEBB TELESCOPE": [],
    "WMO": [],
};



const PopupComponent = ({ togglePopup, isOpen }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDictionary, setSelectedDictionary] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (selectedDictionary && dictionaries[selectedDictionary]) {
            const filtered = dictionaries[selectedDictionary].filter(item =>
                item.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems([]);
        }
    }, [selectedDictionary, searchTerm]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleDictionaryClick = (dictionary) => {
        setSelectedDictionary(dictionary);
    };

    const handleItemToggle = (item) => {
        setSelectedItems(prevItems =>
            prevItems.includes(item)
                ? prevItems.filter(i => i !== item)
                : [...prevItems, item]
        );
    };

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg relative w-3/4 h-3/4 overflow-auto">
                        <div className="flex items-center justify-between">
                            <h2 className="text-2xl top-2 left-2 font-bold mb-4 text-indigo-700">World Atlas of Data</h2>
                            <button
                                onClick={togglePopup}
                                className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded absolute top-2 right-2"
                            >
                                Close
                            </button>
                        </div>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="w-full p-2 mb-4 outline-none border border-indigo-300 rounded"
                        />
                        <div className="flex">
                            <div className="w-1/4 pr-4 overflow-y-auto">
                                {Object.keys(dictionaries).map(dict => (
                                    <button
                                        key={dict}
                                        onClick={() => handleDictionaryClick(dict)}
                                        className={`block w-full text-left p-2 mb-2 rounded ${selectedDictionary === dict ? 'bg-indigo-200' : 'bg-indigo-100 hover:bg-indigo-200'
                                            }`}
                                    >
                                        {dict}
                                    </button>
                                ))}
                            </div>
                            <div className="w-3/4">
                                {selectedDictionary && (
                                    <div>
                                        <h3 className="text-xl font-semibold mb-2 text-indigo-600">{selectedDictionary} Items:</h3>
                                        {filteredItems.map(item => (
                                            <div key={item} className="flex items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    id={item}
                                                    checked={selectedItems.includes(item)}
                                                    onChange={() => handleItemToggle(item)}
                                                    className="mr-2"
                                                />
                                                <label htmlFor={item}>{item}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};